import React from 'react'
import Homeheader from '../components/homeheader'
import FooterN from '../components/FooterN'

const SecurityC = () => {
  return (
    <div>
      <div className='sticky-top bg-white'>
        <Homeheader />
      </div>
      <div className='container fx'>
        <div style={{ fontSize: "48px" }} className='py-3 text-center'>
          Security Center
        </div>
        <div className='row pb-5'>
          <div className='row m-0 fs-5 px-4'>
            At Jobeze.com, we prioritize the security and privacy of our users. We are committed to maintaining a safe and secure environment on our job portal. In our Security Center, we provide you with important information and guidelines to help protect your personal information and ensure a secure experience on Jobeze.com :
          </div>
          <div className='row'>
            <ul className="list-group list-group-numbered fs-5">
              <li className="list-group-item border-0 fw-bolder">
                <span>Account Security :</span>
                <ol className='fs-6 fw-normal' type='a'>
                  <li>Password Protection : Choose a strong and unique password for your Jobeze.com account. Avoid using easily guessable passwords and consider using a combination of letters, numbers, and symbols. Regularly update your password and avoid sharing it with others.</li>
                  <li>Two-Factor Authentication : Enable two-factor authentication (2FA) for an extra layer of security. This adds an additional step to the login process, requiring a verification code sent to your registered mobile device or email address.</li>
                  <li>Account Activity Monitoring : Regularly review your account activity and be vigilant for any unauthorized access or suspicious activity. If you notice any unusual or unauthorized access, immediately change your password and report it to us.</li>
                </ol>
              </li>
              <li className="list-group-item border-0 fw-bolder">
                <span>Phishing and Scam Awareness :</span>
                <ol className='fs-6 fw-normal' type='a'>
                  <li>Phishing Emails : Be cautious of emails claiming to be from Jobeze.com or our representatives asking for personal information, login credentials, or payment details. We will never ask for such information via email. Do not click on suspicious links or download attachments from unfamiliar sources.</li>
                  <li>Suspicious Job Offers : Be wary of job offers that require upfront payments or request sensitive information before the interview process. Research the employer and verify the legitimacy of the job offer before providing any personal information or making any payments.</li>
                </ol>
              </li>
              <li className="list-group-item border-0 fw-bolder">
                <span>Communication Safety :</span>
                <ol className='fs-6 fw-normal' type='a'>
                  <li>Official Channels : Use the communication tools provided within Jobeze.com to interact with employers and job seekers. Avoid sharing personal contact information or communicating through external platforms until you have verified the legitimacy of the other party.</li>
                  <li>Privacy Settings : Review and adjust your privacy settings to control the visibility of your profile and personal information. Limit the amount of personal information you share publicly to reduce the risk of unauthorized access or misuse.</li>
                </ol>
              </li>
              <li className="list-group-item border-0 fw-bolder">
                <span>Reporting Security Issues :</span>
                <div className='fs-6 fw-normal px-4'>If you encounter any security issues, suspected fraudulent activity, or believe that your account has been compromised, please report it to us immediately. Provide detailed information about the incident, including relevant user profiles, job listings, or any suspicious communication.</div>
              </li>
              <li className="list-group-item border-0 fw-bolder">
                <span>Keeping Software Updated :</span>
                <div className='fs-6 fw-normal px-4'>Ensure that your computer or mobile device's operating system, web browser, and antivirus software are up to date with the latest security patches. Regularly install updates to protect against known vulnerabilities and malware.</div>
              </li>
              <li className="list-group-item border-0 fw-bolder">
                <span>Education and Awareness :</span>
                <div className='fs-6 fw-normal px-4'>Stay informed about current security best practices, common scams, and online safety tips. Familiarize yourself with the latest security threats and techniques used by fraudsters. Educate yourself and your team members about potential risks and how to mitigate them.</div>
              </li>
            </ul>
          </div>
          <div className='row m-0 pt-3 px-4 fs-5'>
            Remember, while Jobeze.com takes security measures to protect your information, it is essential for users to remain vigilant and take personal responsibility for their own security. By following these guidelines and using common sense, together we can maintain a secure and trusted job portal environment.
            Please note that the information provided in this Security Center is for guidance purposes only and does not guarantee absolute security. It is important for users to stay informed, exercise caution, and employ good security practices when using Jobeze.com or any online platform.
          </div>
        </div>
      </div>
      <FooterN />
    </div>
  )
}

export default SecurityC