import React from 'react'
import { useNavigate } from 'react-router-dom'
import Homeheader from './homeheader'
import Button from '@mui/material/Button';

const ErrorPage = () => {
  const navigate = useNavigate();
  const handleShow = () => {
    document.cookie.split('_secure_ARJ_')[1] ? navigate('/sectionpage') : navigate('/');
  }
  return (
    <>
      <Homeheader />
      <div className='container-fluid fx m-0 p-0'>
        <div className="">
          <div style={{ height: "80vh" }} className='text-center d-flex justify-content-center align-items-center'>
            <div className=''>
              <div className='d-flex justify-content-center align-items-center'>
                <img className='img-fluid' src="/cactus.svg" alt='' />
              </div>
              <div className='py-3'>
                <div className='col-12 fs-3'>Oops! seems like this page isn’t available</div>
                <div style={{ color: "#00000099" }} className='col-12 fw-bolder '>
                  <p>Try going back to the previous page or to the home page.</p>
                </div>
              </div>
              <div className='col-12'>
                <Button variant='contained' onClick={handleShow} className='fw-bold px-4' >Back to Home</Button>
                {/* <button className='btn btn-primary px-3 text-uppercase' style={{backgroundColor:"#006CB7"}} type='button' onClick={handleShow}>Back to home</button> */}
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default ErrorPage