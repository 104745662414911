import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import axios from 'axios';
import moment from 'moment/moment.js';
import flower from '../images/flower.png';
import Html from '../js/Html';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function JobView(props) {
  const [open, setOpen] = React.useState(true);
  const [title, setTitle] = React.useState('');
  const [companyname, setCompanyName] = React.useState('');
  const [city, setCity] = React.useState('');
  const [state, setState] = React.useState('');
  const [description, setDescription] = React.useState();
  const [getDate, setGetDate] = React.useState('');
  const [logo, setLogo] = React.useState('');
  const [isEmployerApplied, setIsEmployerApplied] = React.useState(0);

  useEffect(() => {
    axios.post(`user/job/job-id/detail`, { token: document.cookie, job_id: props.jobID }).then((response) => {
      setTitle(response?.data?.message[0]?.title);
      setCompanyName(response?.data?.message[0]?.name);
      setCity(response?.data?.message[0]?.city);
      setState(response?.data?.message[0]?.state);
      setDescription({ __html: Html(response?.data?.message[0]?.description) });
      setGetDate(response?.data?.message[0]?.applied_date);
      setLogo(response?.data?.message[0]?.logo);
      setIsEmployerApplied(response?.data?.message[0]?.isEmployerApplied);
    })
  }, [])

  const handleClose = () => {
    setOpen(false);
    props.setIsOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div className="card mb-3" style={{ border: 'none' }}>
            <div className="d-flex justify-content-between bd-highlight">
              <div className="d-flex">
                <img src={logo} className="img-fluid" style={{ width: '', height: '100px' }} alt="..." />
                <div className="card-body" >
                  <h5 className="card-title" style={{ color: '#2D65BC' }}>{title}</h5>
                  <h6 className="card-subtitle mb-1  mt-1">{companyname}</h6>
                  <p className="card-text text-muted">{city}, {state}</p>
                </div>
              </div>
              {
                <div style={{ display: 'flex', marginTop: '10%', gap: '15px' }}>
                  <img src="/rightick.png" alt="" style={{ height: '20px', width: '20px', marginTop: '1%' }} />
                  <p className="view-detail" style={{ cursor: 'pointer', height: '30px', marginBottom: '0px', width: '178px' }}>
                    Applied on {moment(getDate).format("MM/DD/YYYY")}</p>
                </div>
              }
            </div>
          </div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <div>
              <div>
                <h5><b>Description</b></h5>
              </div>
              <div className='fs-5 fontsizehtml' dangerouslySetInnerHTML={description}></div>
            </div>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}