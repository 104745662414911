import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const FooterZ = ({ handleClick }) => {
  const productEngineers = [
    'Embedded Engineer',
    'Network Engineer',
    'Test Engineer'
  ];

  const digitalEngineering = [
    'Artificial Intelligence',
    'Cyber Security',
    'Data Analyst',
    'Data Engineer',
    'Machine Learning',
    'QA Engineer',
    'Security Engineer',
    'Full Stack Developer',
    'Program Manager'
  ];

  const management = [
    'Business Analyst',
    'Product Manager',
    'Project Manager'
  ];

  const nonTech = [
    'Administrator',
    'HR',
    'Mechanical Engineer',
    'Technician',
  ];

  const nav = useNavigate();
  const locations = useLocation();

  const handleSearch = (skill) => {
    const param_title = skill;
    if (locations.pathname.includes("jobsearch")) {
      handleClick(skill);
    } else {
      // nav(`/jobsearch/allsearchdata?jobRole=${param_title}&search=${param_title}`, { state: { skill, param_title } });
    }
  };

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "https://platform.linkedin.com/in.js";
  //   script.type = "text/javascript";
  //   script.async = true;
  //   script.innerHTML = 'lang: en_US';
  //   document.getElementsByTagName('head')[0].appendChild(script);
  // }, []);

  return (
    <>
      <div className='footerMain fx'>
        <div className="footer-1-box py-5" >
          <div className='col-12 logoinMobile '><img className='img-fluid' alt='Jobeze' src='/Logo.svg' /></div>
          <div className="links-section1 links-section ">
            <div className='logoinDesktop'><a href='/'><img style={{ cursor: "pointer" }} className='img-fluid' alt='Jobeze' src='/Logo.svg' /></a></div>
            <div>
              <ul className='ul-list'>
                <li className='common-font pb-4'>Product Engineering</li>
                {productEngineers.map((e, index) => (
                  <Link key={index} to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`} style={{ textDecoration: 'none' }} onClick={() => handleSearch(e)}>
                    <li className='common-font-2 py-1 pb-2'>{e}</li>
                  </Link>
                ))}
              </ul>
            </div>
            <div>
              <ul className='ul-list'>
                <li className='common-font pb-4'>Digital Engineering</li>
                {digitalEngineering.map((e, index) => (
                  <Link key={index} to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`} style={{ textDecoration: 'none' }} onClick={() => handleSearch(e)}>
                    <li className='common-font-2 py-1 pb-2'>{e}</li>
                  </Link>
                ))}
              </ul>
            </div>
            <div>
              <ul className='ul-list'>
                <li className='common-font pb-4'>Management</li>
                {management.map((e, index) => (
                  <Link key={index} to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`} style={{ textDecoration: 'none' }} onClick={() => handleSearch(e)}>
                    <li className='common-font-2 py-1 pb-2'>{e}</li>
                  </Link>
                ))}
              </ul>
            </div>
            <div>
              <ul className='ul-list'>
                <li className='common-font pb-4'>Non Tech</li>
                {nonTech.map((e, index) => (
                  <Link key={index} to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`} style={{ textDecoration: 'none' }} onClick={() => handleSearch(e)}>
                    <li className='common-font-2 py-1 pb-2'>{e}</li>
                  </Link>
                ))}
              </ul>
            </div>
          </div>
          <hr />
          <div className="d-lg-flex justify-content-between pt-4 container-sm">
            <div className="footer-end-col1">
              © 2023 Jobeze. All rights reserved.
            </div>
            <div className="d-flex socialicons " style={{ gap: '15px', marginRight: "45px" }}>
              <a href="https://www.facebook.com/people/Jobeze/100092156896675/" target='_blank' rel='noreferrer'><img src="/facebook.svg" alt="" /></a>
              <a href="https://www.instagram.com/jobeze_job/" target='_blank' rel='noreferrer'><img src="/insta.svg" alt="" /></a>
              <a href="https://twitter.com/jobeze_jobs" target='_blank' rel='noreferrer'><img src="/twitter.svg" alt="" /></a>
              {/* <div className='d-flex gap-2 justify-content-center position-relative overflow-hidden'>
                <a target='_blank' rel='noreferrer'>
                  <img src="/linkdin.svg" alt="" />
                </a>
                <div style={{ position:"absolute" , top:"0", opacity:"0"  }}>
                  <script type="IN/FollowCompany" data-id="93251773"  data-counter="bottom"></script>
                </div>
              </div> */}
              <a href="https://www.linkedin.com/company/jobeze/?isFollowingPage=true" target='_blank' rel='noreferrer'><img src="/linkdin.svg" alt="" /></a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterZ;
