import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, InputAdornment, Typography } from "@mui/material";
import OtpPopup from "./otpPopup";
import { toast } from "react-toastify";
import axios from "axios";

const ProfilePopup = ({
  handleClosePopup,
  emailPopupOpen,
  numberPopupOpen,
  alternativeEmail1,
  setAlternativeEmail1,
  setEmailPopupOpen,
  email1,
  setEmail1,
  phoneNumber,
  setPhoneNumber,
  isPhoneNumberAdded,
  setIsPhoneNumberAdded,
  setNumberPopupOpen,
  data,
  setContacts,
  setEffectDependency,
  phoneNo,
}) => {
  const [emailError, setEmailError] = useState("");
  //const [phoneNoError, setPhoneNoError] = useState('');
  //const [sentOtp, setSentOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const handleCloseEventPopup = () => {
    setAlternativeEmail1("");
    handleClosePopup();
  };

  const [otpScreen, setOtpScreen] = useState(false);

  const handleCloseOtpScreen = () => {
    setOtpScreen(false);
    handleClosePopup();
    setAlternativeEmail1("");
  };

  const handleUpdateEmail1 = (event) => {
    const email = event.target.value;
    setAlternativeEmail1(email);
    setEmailError("");
    if (email.length > 60) {
      setEmailError("Email length cannot exceed 60 characters");
    } else {
      setEmailError('');
    }
  };

  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const handleAlternativeEmail = async (event) => {
    event.preventDefault();
    if (!regex.test(alternativeEmail1)) {
      setEmailError("Please enter a valid email");
      return;
    }
    if (alternativeEmail1.length > 60) {
      setEmailError("Email length cannot exceed 60 characters");
      return; // Stop submission if there's an error
    }
    setLoading(true);
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    const email = formJson.email;
    //console.log(email);
    //setEmail1(email);
    // localStorage.setItem("E1", email);

    const payload = {
      email: alternativeEmail1,
    };

    axios
      .post("user/send-otp", payload, {
        headers: { authorization: document.cookie },
      })
      .then((response) => {
        if (response?.data?.status === 200) {
          setOtpScreen(true);
          //setSentOtp("");
          setLoading(false);
          toast.success("OTP Sent", {
            hideProgressBar: true,
            closeOnClick: true,
          });
        } else {
          setLoading(false);
          toast.error(response?.data?.error, {
            hideProgressBar: true,
            closeOnClick: true,
          });
        }
      })
      .catch((error) => {
        console.log(error, "catch");
        // console.log("Error sending OTP:", error);
        toast.error(error.response.data.error, {
          hideProgressBar: true,
          closeOnClick: true,
        });
        // setAlternativeEmail1("");
        // Handle error if needed
        setLoading(false);
      });
  };

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value;

    const phoneRegex = /^[0-9]{10}$/;
    setIsValid(phoneRegex.test(inputValue));
    setPhoneNumber(inputValue);

    // if(!phoneNo){
    // setPhoneNumber(inputValue);}
    // else {
    //     setPhoneNumber(phoneNo);}
    // if ( !phoneRegex.test(inputValue)) {
    //     setPhoneNoError('Please enter a valid 10-digit phone number.');
    // } else {
    //     setPhoneNoError('');
    // }
  };

  const handlePhoneNumber = async (event) => {
    event.preventDefault();

    // const formData = new FormData(event.currentTarget);
    // const formJson = Object.fromEntries(formData.entries());
    // console.log(formJson, "45646546");
    // const number = formJson.number;
    // console.log(number);
    //     const inputPhoneNumber = event.target.value;

    // if (inputPhoneNumber === phoneNo) {
    //     toast.error("Mobile Number Already Added", { hideProgressBar: true, closeOnClick: true });
    //     return;
    // }

    let payload = {
      mobile: phoneNumber,
    };
    // if (!isValid) {
    //     setPhoneNoError('Please enter a valid phone number.');
    //     return;

    // }
    setIsPhoneNumberAdded(true);
    await axios
      .post("update-alternate-mobile", payload, {
        headers: { authorization: document.cookie },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.success("Mobile Number Added", {
            hideProgressBar: true,
            closeOnClick: true,
          });
          setNumberPopupOpen(false);
          setEffectDependency((prev) => !prev);
        } else {
          toast.error("Mobile Number Not Added", {
            hideProgressBar: true,
            closeOnClick: true,
          });
        }
      });
  };

  return (
    <>
      {emailPopupOpen && (
        <>
          <Dialog
            open={emailPopupOpen}
            PaperProps={{
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                // const formData = new FormData(event.currentTarget);
                // const formJson = Object.fromEntries(formData.entries());
                // const email = formJson.email;
                // console.log(email);

                //handleCloseEventPopup();
                handleAlternativeEmail(event);
              },
            }}
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "8px",
                width: { xs: "90%", sm: "80%" },
                padding: "25px",
                height: "69%",
              },
            }}
          >
            <DialogTitle className=""></DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseEventPopup}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                // color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent align="center" className="d-flex flex-column gap-5">
              <DialogContentText className="text-center fw-bold">
                <Typography gutterBottom>
                  <img
                    src="./emailDummyimage.jpg"
                    alt="Alternate Email"
                    className="img-fluid"
                    width={"85px"}
                  ></img>
                </Typography>
                To get started, Please add Email Address
              </DialogContentText>
              <div className="position-absolute top-50 start-50 translate-middle">
                {loading && <CircularProgress />}
              </div>
              <Box className="pb-3">
                <Typography
                  gutterBottom
                  sx={{ textAlign: "start" }}
                  className="fw-bold"
                >
                  Alternate Email
                </Typography>
                <TextField
                  id="name"
                  name="email"
                  label="Enter email address"
                  // type="email"
                  fullWidth
                  maxLength={60}
                  value={alternativeEmail1}
                  error={emailError !== ""}
                  helperText={emailError}
                  variant="outlined"
                  onChange={handleUpdateEmail1}
                  autoComplete="off"
                />
              </Box>
            </DialogContent>
            {otpScreen && (
              <OtpPopup
                open={otpScreen}
                setOpen={setOtpScreen}
                setEffectDependency={setEffectDependency}
                handleClose={handleCloseOtpScreen}
                setEmailPopupOpen={setEmailPopupOpen}
                alternativeEmail1={alternativeEmail1}
                setAlternativeEmail1={setAlternativeEmail1}
                setContacts={setContacts}
              />
            )}
            <DialogActions className="px-5" sx={{ justifyContent: "center" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "rgba(5, 83, 155, 1)",
                  letterSpacing: "1.5px",
                }}
                className="fw-bold text-capitalize rounded-3"
                size="large"
                fullWidth
                type="submit"
                disabled={loading}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {numberPopupOpen && (
        <Dialog
          open={numberPopupOpen}
          PaperProps={{
            component: "form",
            onSubmit: (event) => {
              event.preventDefault();
              handlePhoneNumber(event);

              //handleCloseEventPopup();
            },
          }}
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "8px",
              width: { xs: "90%", sm: "80%" },
              padding: "25px",
              height: "70%",
            },
          }}
        >
          <DialogTitle className=""></DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseEventPopup}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              // color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent align="center" className="d-flex flex-column gap-5">
            <DialogContentText className="text-center fw-bold">
              <Typography gutterBottom>
                <img
                  src="./mobilePopupImage.jpg"
                  alt="Alternate Email"
                  className="img-fluid"
                  width={"85px"}
                ></img>
              </Typography>
              {/* To get started, Please verify Mobile Number */}
              Add your Mobile Number here
            </DialogContentText>

            <Box className="pb-3">
              <Typography
                gutterBottom
                sx={{ textAlign: "start" }}
                className="fw-bold"
              >
                Mobile Number
              </Typography>
              <TextField
                id="outlined-start-adornment"
                name="number"
                fullWidth
                placeholder="Enter Mobile Number"
                variant="outlined"
                autoComplete="off"
                className="fw-bold"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                onPaste={(e) => e.preventDefault()}
                // error={phoneNoError}
                // helperText={phoneNoError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="fw-bold" position="start">
                      + 1
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 10,
                  onKeyPress: (e) => {
                    const charCode = e.which ? e.which : e.keyCode;
                    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                      e.preventDefault();
                    }
                  },
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions className="px-5" sx={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "rgba(5, 83, 155, 1)",
                letterSpacing: "1.5px",
              }}
              disabled={phoneNumber.length !== 10}
              className="fw-bold text-capitalize rounded-3"
              size="large"
              fullWidth
              type="submit"
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ProfilePopup;
