
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Linearprogress from "./Linearprogress";

const Userprofilecompletion = (props) => {

  const [profileCompletion, setProfileCompletion] =  useState(true);
  const sum = props.completeprofile;
 
  const handleClose = () => {
    setProfileCompletion(false);
  }

useEffect(() => {
  if(props.completeprofile === "100"){
    setProfileCompletion(false);
  }
  else {
    setProfileCompletion(true);
  }
},[props.completeprofile])

  return (
    <div>
      {profileCompletion  && <div>
        <div className="jobeze-progress-profile">
          <div className="jobeze-progress-div1">
              <div className="jobeze-progress-accounticon">
              {/* <AccountCircleIcon fontSize="medium"/> */}
              <img src='/user-icon.svg' alt='error' style={{height:'22px',width:'22px'}} />
              </div>
              <div className="jobeze-progress-status">
                Your profile is {sum +'%'} complete. Fill all the details in order to complete your profile.
              </div>
          </div>
          <div className="jobeze-progress-crossicon" style={{cursor:'pointer'}}>
              <CloseIcon fontSize="medium" onClick={handleClose} /> 
            </div>
        </div>
        <div style={{marginTop:'-4px', marginBottom: "24px"}}>
          <Linearprogress data={sum}/>
        </div>
      </div>}
        
    </div>
  )
}

export default Userprofilecompletion
