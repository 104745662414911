import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { Box, List, Stack, useMediaQuery } from '@mui/material';
import Card from '../components/card';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import JobApply from '../components/jobApply';
import FooterZ from '../components/FooterZ';
import FooterN from '../components/FooterN';
import JobPostedFilter from '../components/jobPostedFilter';
import JobFilters from '../components/jobFilters';
import Backdrops from '../components/Backdrop';
import Errorpage from '../components/Error404';
import AlertShow from '../components/AlertShow';
import Homeheader from '../components/homeheader';
import { useAppSelector } from '../redux/hooks';
import { selectUser } from '../redux/modules/userSlice';
import Jobapplyshimmer from '../components/Jobapplyshimmer';
import Descriptionshimmer from './Descriptionshimmer';
import Header from '../components/header';
import { useCookies } from 'react-cookie';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import FilterListIcon from '@mui/icons-material/FilterList';


// import { MobileFilter } from '../components/MobileFilter';

function Jobsearchpage(props) {
  const [cookies, setCookie] = useCookies([]);

  const isSmallScreen = useMediaQuery('(max-width: 625px)');
  const isMediumScreen = useMediaQuery('(max-width: 1200px)');

  const { isVerified } = useAppSelector(selectUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const baseURL = process.env.REACT_APP_BASEURL;
  const nav = useNavigate();
  const locations = useLocation();
  const token = document.cookie;
  const [loader, setLoader] = useState(false); //loader backdrop
  const [verified, setVerified] = useState(false); //User email Verification State
  const [skills, setSkills] = useState();
  const [cardshimmer, setcardshimmer] = useState(false);
  const [descShimmer, setdescShimmer] = useState(false);

  // states for job data
  const [jobcards, setJobcards] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});

  const [desc, setDesc] = useState(
    locations.state ? (locations.state.skill ? locations.state.skill : '') : ''
  );
  // filters states
  const [company, setCompany] = useState([]);
  const [skill, setSkill] = useState(
    searchParams.get('search') ? searchParams.get('search') : ''
  );
  const [location, setLocation] = useState(
    searchParams.get('location') ? searchParams.get('location') : 'World Wide'
  );
  const [roles, setRoles] = useState(
    locations?.state?.category ? locations?.state?.category : []
  );
  const [jobtypes, setJobtypes] = useState([
    { name: 'Contract', value: 'C', isSelected: false },
    { name: 'Contract to hire', value: 'C2H', isSelected: false },
    { name: 'Freelance', value: 'F', isSelected: false },
    { name: 'Hire', value: 'H', isSelected: false },
  ]);
  const [jobPosted, setJobPosted] = useState([
    { label: 'Any Time', value: 'Any Time', isSelected: false },
    { label: 'Past Week', value: 'Past Week', isSelected: false },
    { label: 'Last 24 Hours', value: 'Last 24 Hours', isSelected: false },
    { label: 'Past Month', value: 'Past Month', isSelected: false },
  ]);
  const [border, setBorder] = useState(false); // active job border state
  const [workMode, setWorkMode] = useState([]); // work mode state
  const [page, setPage] = useState(
    locations?.state?.pageData ? locations?.state?.pageData : 1
  ); //Pagination State

  const [totalPages, setTotalPages] = useState(); //Pagination State
  const [searchResults, setSearchResults] = useState(false); // search results
  const [isdelay, setisdelay] = useState(false);

  const fetchData = async () => {
    setLoader(true);
    setdescShimmer(true);
    setcardshimmer(true);
    const response = await getCompanyAndRole();
    if (response) {
      setTimeout(() => {
        setcardshimmer(false);
        setdescShimmer(false);
      }, 1000);
      const { companyData, roleData, workmode } = await getCompanyAndRole();
      const finalCompany = companyFromUrl(companyData);
      setCompany(finalCompany);
      const finalRoles = rolesFromUrl(roleData);
      setRoles(finalRoles);
      const workmodeupdatData = workModeUrl(workmode);
      setWorkMode(workmodeupdatData);
      jobTypeFromUrl(jobtypes);
      const { jobCardData, totalPages } = await getJobCards();
      locations?.state?.job
        ? setSelectedJob(locations?.state?.job)
        : setSelectedJob(
          jobCardData.message.dashboard.data.length > 0
            ? jobCardData.message.dashboard.data[0]
            : ''
        );
      setJobcards(jobCardData.message.dashboard.data);
      setTotalPages(totalPages);
      setLoader(false);
      setBorder(true);
    } else {
      // Handle the case when getCompanyAndRole returns null
      console.error('Error fetching company and role data');
      // Additional error handling or fallback logic can be implemented here
      setLoader(false);
    }
  };

  const rolesFromUrl = (allRoles) => {
    let urlRoles = searchParams.get('jobRole')
      ? searchParams.get('jobRole').split(',')
      : [];
    let tempRoles = [...allRoles];
    tempRoles.forEach((role) => {
      if (urlRoles.includes(role.name)) role.isSelected = true;
    });
    return tempRoles;
  };
  const companyFromUrl = (allCompany) => {
    let urlCompany = searchParams.get('Searchedcompany')
      ? searchParams.get('Searchedcompany').split(',')
      : [];
    let tempCompany = [...allCompany];
    tempCompany.forEach((comp) => {
      if (urlCompany.includes(comp.company_id.toString())) {
        // console.log(comp.name, " urlCompany")
        comp.isSelected = true;
      }
    });
    return tempCompany;
  };
  const jobTypeFromUrl = (alljobTypes) => {
    let urlTypes = searchParams.get('jobType')
      ? searchParams.get('jobType').split(',')
      : [];
    let tempjobTypes = [...alljobTypes];
    tempjobTypes.forEach((jobT) => {
      if (urlTypes.includes(jobT.value)) jobT.isSelected = true;
    });
  };

  const workModeUrl = (allWorkMode) => {
    let urlWorkMode = searchParams.get('workMode')
      ? searchParams.get('workMode').split(',')
      : [];
    let tempWorkMode = [...allWorkMode];
    tempWorkMode.forEach((mode) => {
      if (urlWorkMode.includes(mode.id.toString())){
          mode.isSelected = true;
        }
    });
    return tempWorkMode;
  }

  const resetAPICall = async (resetNum) => {
    setcardshimmer(true);
    setdescShimmer(true);
    const [companies, category, jobType, jobPost, workModeLoad] = getPayload();
    let cardUrl = '';
    if (cookies._secure_ARJ_ || cookies._secure_ref) {

      cardUrl = `user/dashboard/page-no/${1}`;
    } else {
      cardUrl = `search/page-no/${1}`;
    }
    const response = await axios.post(cardUrl, {
      description: skill ? skill : desc,
      location: searchParams.get('location') === "World Wide" ? "" : searchParams.get('location'),
      title: searchParams.get('jobtitle') ? searchParams.get('jobtitle') : '',
      token: document.cookie.split(';')[2].split(';')[0],
      page: 1,
      company: resetNum === 4 ? '' : companies.length > 0 ? companies : '',
      jobPostedOn: resetNum === 1 ? 'Any Time' : jobPost[0],
      category: resetNum === 3 ? '' : category.length > 0 ? category : '',
      jobType: resetNum === 2 ? '' : jobType.length > 0 ? jobType : '',
      workmode: resetNum === 5 ? '' : workModeLoad.length > 0 ? workModeLoad : '',
    });
    const jobCardData = response?.data;
    const totalPages = response?.data.message.dashboard.total_page;
    setSelectedJob(
      jobCardData.message.dashboard.data.length > 0
        ? jobCardData.message.dashboard.data[0]
        : {}
    );
    setJobcards(jobCardData.message.dashboard.data);
    setTotalPages(totalPages);
    setTimeout(() => {
      setcardshimmer(false);
      setdescShimmer(false);
    }, 1000);
  };
  useEffect(() => {
    const loadData = async () => {
      const { jobCardData, totalPages } = await getJobCards(1, searchParams);
      setSelectedJob(
        jobCardData.message.dashboard.data.length > 0
          ? jobCardData.message.dashboard.data[0]
          : {}
      );
      setJobcards(jobCardData.message.dashboard.data);
      setTotalPages(totalPages);
    };
    loadData();
    window.scrollTo(0, 0);
  }, [searchParams]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [desc]);

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (skill) {
      window.scrollTo(0, 0);
      setSearchResults(true);
    } else {
      setSearchResults(false);
    }
  }, [skill]);

  useEffect(() => {
    setSkill(searchParams.get('search'));
    fetchData();
    window.scrollTo(0, 0);
  }, [searchParams, location]);

  async function getCompanyAndRole() {
    try {
      setcardshimmer(true);
      setdescShimmer(true);
      const response = await axios.get(baseURL + '/all-category',{
        headers: {
          Authorization: document.cookie,
        }
      });
      const { company, category, workmode } = response?.data?.message;
      const roleValue = category.map((item) => {
        return {
          name: item.name,
          isSelected:
            locations?.state?.category &&
              locations?.state?.category.find((o) => o.name === item.name)
              ? true
              : false,
        };
      });


      setTimeout(() => {
        setcardshimmer(false);
        setdescShimmer(false);
      }, 1000);
      return {
        companyData: company,
        roleData: roleValue,
        workmode

      };
    } catch (error) {
      setTimeout(() => {
        setcardshimmer(false);
        setdescShimmer(false);
      }, 1000);
      console.error('Error fetching data:', error);
      return null;
    }
  }

  function getPayload() {
    const companies = company
      .filter((e) => e.isSelected)
      .map((e) => e.company_id);
    const category = locations?.state?.category
      ? locations?.state?.category
        .filter((e) => e.isSelected)
        .map((e) => e.name)
      : roles.filter((e) => e.isSelected).map((e) => e.name);
    const jobPost = jobPosted.filter((e) => e.isSelected).map((e) => e.value);
    const jobType = jobtypes.filter((e) => e.isSelected).map((e) => e.value);
    const workModeLoad = workMode.filter((e) => e.isSelected).map((e) => e.id);
    return [companies, category, jobType, jobPost, workModeLoad];
  }
  useEffect(() => {
    setTimeout(() => {
      setisdelay(true);
    }, 500);
  }, []);
  async function getJobCards(value) {
    try {
      let pageno = value ? value : page;
      const [companies, category, jobType, jobPost, workModeLoad] = getPayload();
      let cardUrl = '';

      if (cookies._secure_ARJ_ || cookies._secure_ref) {
        // console.log("314", cookies._secure_ref)
        cardUrl = `user/dashboard/page-no/${pageno}`;
      } else {

        cardUrl = `search/page-no/${pageno}`;
      }

      setcardshimmer(true);
      setdescShimmer(true);
      const response = await axios.post(cardUrl, {
        // description: skill ? skill : "",
        description: searchParams.get('search')
          ? searchParams.get('search')
          : '',
        location: searchParams.get('location') === "World Wide" ? "" : searchParams.get('location'),
        token: document.cookie,
        page: page,
        company: searchParams.get('Searchedcompany')
          ? searchParams.get('Searchedcompany').split(',')
          : companies.length > 0
            ? companies
            : '',
        jobPostedOn: searchParams.get('jobPosted')
          ? searchParams.get('jobPosted')
          : 'Any Time',
        category: searchParams.get('jobRole')
          ? searchParams.get('jobRole').split(',')
          : category.length > 0
            ? category
            : '',
        jobType: searchParams.get('jobType')
          ? searchParams.get('jobType').split(',')
          : jobType.length > 0
            ? jobType
            : '',
        workmode: searchParams.get('workMode')
          ? searchParams.get('workMode').split(',')
          : workModeLoad.length > 0
            ? workModeLoad
            : '',
      });
      const jobCardData = response?.data;
      const totalPages = response?.data.message.dashboard.total_page;
      setTimeout(() => {
        setcardshimmer(false);
        setdescShimmer(false);
      }, 1000);
      return { jobCardData, totalPages };
    } catch (error) {
      setTimeout(() => {
        setcardshimmer(false);
        setdescShimmer(false);
      }, 1000);
      console.error('Error fetching data:', error);
      return null;
    }
  }
  //Job Posted function
  const handleJobPostedChange = (e, index) => {
    const { checked } = e.target;
    setJobPosted((prevJobPosted) =>
      prevJobPosted.map((posted, i) =>
        i === index
          ? { ...posted, isSelected: checked }
          : { ...posted, isSelected: false }
      )
    );
  };
  const handleJobPostedReset = (isResetAll = true) => {
    return new Promise((resolve) => {
      const updatedJobPosted = jobPosted.map((jobposted) => ({
        ...jobposted,
        isSelected: false,
      }));
      updatedJobPosted[0].isSelected = true;
      const [jobRoles, companyFilter, jobTypeFilter, jobPostFilter, workModeFilter] =
        createUrlParams();
      setSearchParams({
        search: skill ? skill : '',
        location: location ? location : '',
        jobPosted: 'Any Time',
        jobType: jobTypeFilter ? jobTypeFilter : '',
        jobRole: jobRoles ? jobRoles : '',
        Searchedcompany: companyFilter ? companyFilter : '',
        workMode: workModeFilter ? workModeFilter : '',
      });
      setJobPosted(updatedJobPosted);
      resolve();
      isResetAll && resetAPICall(1);
    });
  };
  // Job Type Function
  const handleJobTypeChange = (e, index) => {
    const { checked } = e.target;
    setJobtypes((prevJobTypes) =>
      prevJobTypes.map((comp, i) =>
        i === index ? { ...comp, isSelected: checked } : comp
      )
    );
  };
  const handleJobTypeReset = (isResetAll = true) => {
    return new Promise((resolve) => {
      const updatedJobTypes = jobtypes.map((jobtype) => ({
        ...jobtype,
        isSelected: false,
      }));
      const [jobRoles, companyFilter, jobTypeFilter, jobPostFilter, workModeFilter] =
        createUrlParams();
      setSearchParams({
        search: skill ? skill : '',
        location: location ? location : '',
        jobType: '',
        jobRole: jobRoles ? jobRoles : '',
        Searchedcompany: companyFilter ? companyFilter : '',
        jobPosted: jobPostFilter ? jobPostFilter : '',
        workMode: workModeFilter ? workModeFilter : '',
      });
      setJobtypes(updatedJobTypes);
      isResetAll && resetAPICall(2);
      resolve();
    });
  };

  // Company Function
  const handleCompanyChange = (e, index) => {
    const { checked } = e.target;
    setCompany((prevCompany) =>
      prevCompany.map((comp, i) =>
        i === index ? { ...comp, isSelected: checked } : comp
      )
    );
  };
  const handleCompanyReset = (isResetAll = true) => {
    return new Promise((resolve) => {
      const tempCompany = company.map((ele, index) => ({
        ...ele,
        isSelected: false,
      }));
      const [jobRoles, companyFilter, jobTypeFilter, jobPostFilter, workModeFilter] =
        createUrlParams();
      setSearchParams({
        search: skill ? skill : '',
        location: location ? location : '',
        jobType: jobTypeFilter ? jobTypeFilter : '',
        jobRole: jobRoles ? jobRoles : '',
        Searchedcompany: '',
        jobPosted: jobPostFilter ? jobPostFilter : '',
        workMode: workModeFilter ? workModeFilter : '',
      });
      setCompany(tempCompany);
      isResetAll && resetAPICall(4);
      resolve();
    });
  };

  // Job Role Function
  const handleRoleChange = (e, index) => {
    locations.state = undefined;
    const { checked } = e.target;
    setRoles((prevRoles) =>
      prevRoles.map((role, i) =>
        i === index ? { ...role, isSelected: checked } : role
      )
    );
  };
  const handleJobRoleReset = (isResetAll = true) => {
    return new Promise((resolve) => {
      const tempRoles = roles.map((role) => ({
        ...role,
        isSelected: false,
      }));
      const [jobRoles, companyFilter, jobTypeFilter, jobPostFilter, workModeFilter] =
        createUrlParams();
      setSearchParams({
        search: skill ? skill : '',
        location: location ? location : '',
        jobType: jobTypeFilter ? jobTypeFilter : '',
        jobRole: '',
        Searchedcompany: companyFilter ? companyFilter : '',
        jobPosted: jobPostFilter ? jobPostFilter : '',
        workMode: workModeFilter ? workModeFilter : '',
      });
      setRoles([...tempRoles]);
      isResetAll && resetAPICall(3);
      resolve();
    });
  };

  const handleWorkModeChange = (e, index) => {
    const { checked } = e.target;
    setWorkMode((prevWorkMode) =>
      prevWorkMode.map((mode, i) =>
        i === index ? { ...mode, isSelected: checked } : mode
      )
    );
  }

  const handleWorkModeReset = (isResetAll = true) => {
    return new Promise((resolve) => {
      const tempWorkMode = workMode.map((mode) => ({
        ...mode,
        isSelected: false,
      }));
      const [jobRoles, companyFilter, jobTypeFilter, jobPostFilter, workModeFilter ] =
        createUrlParams();
        setSearchParams({
        search: skill ? skill : '',
        location: location ? location : '',
        jobType: jobTypeFilter ? jobTypeFilter : '',
        jobRole: jobRoles ? jobRoles : '',
        Searchedcompany: companyFilter ? companyFilter : '',
        jobPosted: jobPostFilter ? jobPostFilter : '',
        workMode: '',
      });
      setWorkMode(tempWorkMode);
      isResetAll && resetAPICall(5);
      resolve();
    })
  }

  //paginations function
  const handlePageChange = async (event, value) => {
    setPage(value);
    const { jobCardData, totalPages } = await getJobCards(value);
    setSelectedJob(
      jobCardData.message.dashboard.data.length > 0
        ? jobCardData.message.dashboard.data[0]
        : {}
    );
    setJobcards(jobCardData.message.dashboard.data);
    setTotalPages(totalPages);
    document.getElementById('divscroll').scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const createUrlParams = () => {
    const jobRoles = roles
      .filter((role) => role.isSelected)
      .map((role) => role.name)
      .join(',');
    const companyFilter = company
      .filter((comp) => comp.isSelected)
      .map((comp) => comp.company_id)
      .join(',');
    const jobTypeFilter = jobtypes
      .filter((e) => e.isSelected)
      .map((e) => e.value)
      .join(',');
    const jobPostFilter = jobPosted
      .filter((e) => e.isSelected)
      .map((e) => e.value)
      .join(',');
    const workModeFilter = workMode
      .filter((e) => e.isSelected)
      .map((e) => e.id)
      .join(',');
    return [jobRoles, companyFilter, jobTypeFilter, jobPostFilter , workModeFilter];
  };

  //FilterShow function
  const handleShow = async () => {
    const [jobRoles, companyFilter, jobTypeFilter, jobPostFilter, workModeFilter] =
      createUrlParams();
    setPage(1);
    setSearchParams({
      search: skill ? skill : '',
      location: location ? location : '',
      jobType: jobTypeFilter ? jobTypeFilter : '',
      jobRole: jobRoles ? jobRoles : '',
      Searchedcompany: companyFilter ? companyFilter : '',
      jobPosted: jobPostFilter ? jobPostFilter : '',
      workMode: workModeFilter ? workModeFilter : ''
    });
    setState({
      right: false
    })
  };

  //filter Reset all function
  const handleResetAll = async () => {
    await handleJobRoleReset(false);
    await handleJobTypeReset(false);
    await handleCompanyReset(false);
    await handleJobPostedReset(false);
    await handleWorkModeReset(false);
    setPage(1);
    const { jobCardData, totalPages } = await resetCall();
    setSelectedJob(
      jobCardData.message.dashboard.data.length > 0
        ? jobCardData.message.dashboard.data[0]
        : {}
    );
    setJobcards(jobCardData.message.dashboard.data);
    setTotalPages(totalPages);
    setState({
      right: false
    })
  };

  //Reset all Function
  async function resetCall() {
    let cardUrl = '';
    if (cookies._secure_ARJ_ || cookies._secure_ref) {
      console.log("618")

      cardUrl = `user/dashboard/page-no/${1}`;
    } else {
      cardUrl = `search/page-no/${1}`;
    }
    setcardshimmer(true);
    setdescShimmer(true);

    const response = await axios.post(cardUrl, {
      description: skill ? skill : desc ? desc : '',
      location: searchParams.get('location') === "World Wide" ? "" : searchParams.get('location'),
      title: searchParams.get('jobtitle') ? searchParams.get('jobtitle') : '',
      token: document.cookie,
      page: 1,
      jobPostedOn: 'Any Time',
    });
    const jobCardData = response?.data;
    const totalPages = response?.data.message.dashboard.total_page;
    setTimeout(() => {
      setcardshimmer(false);
      setdescShimmer(false);
    }, 1000);
    setSearchParams({ search: skill ? skill : '', location: location });
    setDesc('');
    return { jobCardData, totalPages };
  }

  //JobApply Function
  const handleApply = (jobId, value) => {
    setSelectedJob({ ...selectedJob, job_applied: new Date() });
    let tempJobs = [...jobcards];
    let index = tempJobs.findIndex((e) => e.joborder_id === jobId);
    if (tempJobs[index].redirect_link) {
      window.open(tempJobs[index].redirect_link);
      return;
    }
    tempJobs[index].job_applied = new Date();
    setJobcards(tempJobs);
  };

  const handleFooterClick = (search) => {
    const [jobCardData, totalPages] = getJobCards(1, search);
    const [jobRoles, companyFilter, jobTypeFilter, jobPostFilter, workModeFilter] =
      createUrlParams();
    setSkill(search);
    setDesc(search);
  };


  const handleJobCardClick = (e) => {
    setSelectedJob(e);

    if (isSmallScreen) {
      nav(`/jobDetail/${e.joborder_id}`);
    }
  }
  //  mobile filter 
  const [state, setState] = useState({
    right: false, // Only the right anchor
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: '100vw' }}
      role="presentation"
      className="fx"
    //   onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="border-bottom">
        <div className="d-flex px-4">
          <div className="col-9 filterHeading fw-bold">All Filters</div>
          <div className="col-3 text-end">
            <CloseIcon onClick={toggleDrawer(anchor, false)} />
          </div>
        </div>
      </List>
      <div className='px-4 py-2 d-flex flex-column gap-2' >
        <List className="border-bottom">
          <div className=" mobileFilterHeadings fw-bold">Date Posted On</div>
          <div className="row m-0 ">
            <div className="col-12 row row-cols-2 p-0 m-0 ">
              {jobPosted.map((item, index) => {
                return (
                  <div key={index} className="d-flex py-2 fw-bold ">
                    <input type="radio" name="jobPosted" value={item.value} checked={item.isSelected}
                      onChange={(e) => {
                        // e.stopPropagation();
                        handleJobPostedChange(e, index);
                      }}
                    id="" />
                    <span className="px-2 fs-14 filter">{item.label}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </List>
        <List className="border-bottom">
          <div className=" mobileFilterHeadings fw-bold">Job Type</div>
          <div className="row m-0">
            <div className="col-12 row row-cols-2 p-0 m-0 ">
              {jobtypes.map((item, index) => {
                return (
                  <div key={index} className="d-flex py-2 fw-bold">
                    <input type="checkbox" name="jobType" value={item.value} checked={item.isSelected} 
                      onChange={(e) => {
                        // e.stopPropagation();
                        handleJobTypeChange(e, index);
                      }}
                    id="" />
                    <span className=" fs-14 px-2 filter">{item.name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </List>
        <List className="border-bottom">
          <div className="mobileFilterHeadings fw-bold pb-2">Job Role</div>
          <div className="row jbscroll" style={{ height: '192px' }}>
            <div className="col-12 row row-cols-2 p-0 m-0">
              {roles.map((item, index) => {
                return (
                  <div className="d-flex py-2 fw-bold " key={index}>
                    <input type="checkbox" className='mx-2' value={item.value} checked={item.isSelected} 
                      onChange={(e) => {
                        // e.stopPropagation();
                        handleRoleChange(e, index);
                      }}
                    name="jobRole" id="" />
                    <div className="text-truncate fs-14 w-100 filter" title={item.name} >
                      {item.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </List>
        <List className="border-bottom">
          <div className="mobileFilterHeadings fw-bold pb-2">Company</div>
          <div className="row jbscroll" style={{ height: '192px' }}>
            <div className="col-12 row row-cols-2 p-0 m-0">
              {company.map((item, index) => {
                return (
                  <div className="d-flex py-2 fw-bold " key={index}>
                    <input type="checkbox" name="company" value={item.value} checked={item.isSelected} 
                      onChange={(e) => {
                        // e.stopPropagation();
                        handleCompanyChange(e, index);
                      }}
                    className='mx-2' id="" />
                    <div className="text-truncate fs-14 w-100 filter" title={item.name} >
                      {item.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </List>
        <List className="">
          <div className=" mobileFilterHeadings fw-bold">Work Mode</div>
          <div className="row m-0">
            <div className="col-12 row row-cols-2 p-0 m-0 ">
              {workMode.map((item, index) => {
                return (
                  <div key={index} className="d-flex py-2 fw-bold">
                    <input type="checkbox" name="jobType" value={item.value} checked={item.isSelected}
                      onChange={(e) => {
                        // e.stopPropagation();
                        handleWorkModeChange(e, index);
                      }}
                      id="" />
                    <span className=" fs-14 px-2 text-capitalize filter">{item.name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </List>
      </div>
      <div className='border-top d-flex align-items-center justify-content-end'>
        <List className='d-flex align-items-center justify-content-end px-3 '>
          <Button variant='text' className=' mx-3 fw-bold' onClick={() => handleResetAll()}  >RESET</Button>
          <Button variant="contained" onClick={handleShow} className='fw-bold'>SHOW RESULTS</Button>
        </List>
      </div>
    </Box>
  );


  return (
    <>
      {isSmallScreen && (
        <>
          <Homeheader />
          <div style={{background : "#F1F1F1"}}>
            {cookies._secure_ARJ_ || cookies._secure_ref ? (
              isVerified ? (
                <></>
              ) : (
                <div id="divscroll2" className="rounded-0">
                  <AlertShow
                    email={props.email}
                    verified={verified}
                    setAlert={setVerified}
                    title={
                      'Verify your email to apply for jobs'
                    }
                    status={'error'}
                  />
                </div>
              )
            ) : (
              <> </>
            )}
            <div className='fx py-1'>
              <div className=''>
                <Button className='fw-bold' onClick={toggleDrawer('right', true)}>
                  <FilterListIcon className='mx-2' /> Filters
                </Button>
                <Button onClick={() => handleResetAll()} variant='text' className='px-3 fw-bold text-dark resetBtn'>Reset</Button>
                <Drawer
                  anchor="right"
                  open={state['right']}
                  onClose={toggleDrawer('right', false)}
                >
                  {list('right')}
                </Drawer>
              </div>
            </div>
            {searchResults ? (
              <div id="divscroll" className="container py-2 text-muted fx fw-bold">
                Showing Results for{' '}
                <span className="text-capitalize">
                  " {searchParams.get('search') || skill} "
                </span>
              </div>
            ) : (
              <></>
            )}
            {
              cardshimmer ? (
                // Display the shimmer/loader component
                <>
                  {
                    jobcards.map((item, index) => {
                      return <Jobapplyshimmer />
                    })
                  }
                </>
              ) : jobcards.length > 0 ? (
                // Display the Card component
                  <div id="divscroll" className=' jbscroll px-3' style={{ overflowY: "scroll" }} >
                    {jobcards.map((item, index) => {
                      return (
                        <div key={index}>
                          {!cardshimmer ? (
                            <Card
                              data={item}
                              key={index}
                              tempId={setSelectedJob}
                              selected={selectedJob}
                              handleJobCardClick={(e) => handleJobCardClick(e)}
                            />
                          ) : (
                            <Jobapplyshimmer />
                          )}
                        </div>
                      );
                    })}
                  </div>
              ) : (
                // If jobcards is empty and shimmer/loader is done, display the Errorpage component
                    <Errorpage
                      jobcards={jobcards}
                      border={border}
                      setSelectedJob={setSelectedJob}
                      selectedJob={selectedJob}
                      totalPages={totalPages}
                      page={page}
                      handlePageChange={handlePageChange}
                      roles={roles}
                      company={company}
                      token={token}
                      loader={loader}
                      setLoader={setLoader}
                      handleApply={handleApply}
                      verified={verified}
                      setVerified={setVerified}
                    />
              )
            }
            <div className='px-3 py-3'>
              <Stack spacing={2}>
                <Pagination
                  className='justify-content-center align-items-center m-auto'
                  count={totalPages}
                  page={page}
                  size='small'
                  color="primary"
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
            {document.cookie.split('_secure_ARJ_')[1] ? (
              <FooterZ handleClick={handleFooterClick} />
            ) : (
              <FooterN handleClick={handleFooterClick} />
            )}
          </div>
          
        </>
      )}
      {isMediumScreen && !isSmallScreen && (
        <>
          {loader ? (
            <>
              <Header />
              {document.cookie.split('_secure_ARJ_')[1] ? (
                <FooterZ />
              ) : (
                <FooterN />
              )}
            </>
          ) : (
            <div style={{ background: 'rgb(246, 246, 246)' }}>
              <Homeheader
                skill={skill}
                // setSkill={setSkill}
                // location={location}
                setLocation={setLocation}
              // handleSearch={handleShow}
              />
              <>
                <div
                  className="py-1 sticky-top bg-white mb-3"
                  style={{ top: '45px', zIndex: '1'}}
                >
                  <div
                    className="d-flex flex-wrap m-0 gap-0"
                    style={{ boxSizing: 'border-box', letterSpacing: '0.7px' }}
                  >
                    {jobPosted.length > 0 && (
                      <JobPostedFilter
                        name={'Job Posted On'}
                        menuOptions={jobPosted}
                        changeRoles={handleJobPostedChange}
                        reset={handleJobPostedReset}
                        show={handleShow}
                      />
                    )}
                    {jobtypes.length > 0 && (
                      <JobFilters
                        name={'Job Type'}
                        count={jobtypes.filter((e) => e.isSelected).length}
                        menuOptions={jobtypes}
                        changeRoles={handleJobTypeChange}
                        reset={handleJobTypeReset}
                        show={handleShow}
                      />
                    )}
                    {roles.length > 0 && (
                      <JobFilters
                        name={'Job Role'}
                        count={roles.filter((e) => e.isSelected).length}
                        menuOptions={roles}
                        changeRoles={handleRoleChange}
                        reset={handleJobRoleReset}
                        show={handleShow}
                      />
                    )}
                    {company.length > 0 && (
                      <JobFilters
                        name={'Company'}
                        count={company.filter((e) => e.isSelected).length}
                        menuOptions={company}
                        changeRoles={handleCompanyChange}
                        reset={handleCompanyReset}
                        show={handleShow}
                      />
                    )}
                    {workMode.length > 0 && (
                        <JobFilters
                            name={'Work Mode'}
                            count={workMode.filter((e) => e.isSelected).length}
                            menuOptions={workMode}
                            changeRoles={handleWorkModeChange}
                            reset={handleWorkModeReset}
                            show={handleShow}
                          />
                        )
                      }
                      { 
                        roles.filter((e) => e.isSelected).length > 0 ||
                        jobtypes.filter((e) => e.isSelected).length > 0 ||
                        company.filter((e) => e.isSelected).length > 0 ||
                        workMode.filter((e) => e.isSelected).length > 0
                          ? (
                              <Button
                                variant="text"
                                size="medium"
                                onClick={() => handleResetAll()}
                                className="px-4 fw-bolder rounded-pill"
                                sx={{ letterSpacing: '2px' }}
                              >
                                Reset all
                              </Button>
                            ) : ('')
                        }
                  </div>
                </div>
                {cookies._secure_ARJ_ || cookies._secure_ref ? (
                  isVerified ? (
                    <></>
                  ) : (
                    <div id="divscroll2" className="container-fluid my-2 mt-0">
                      <AlertShow
                        email={props.email}
                        verified={verified}
                        setAlert={setVerified}
                        title={
                          'Verify your email id to continue applying for jobs.'
                        }
                        status={'error'}
                      />
                    </div>
                  )
                ) : (
                  <> </>
                )}
                {searchResults ? (
                  <div className="px-3 pb-2 text-muted fx fw-bold">
                    Showing Results for{' '}
                    <span className="text-capitalize">
                      " {searchParams.get('search') || skill} "
                    </span>
                  </div>
                ) : (
                  <></>
                )}
                {jobcards.length > 0 ? (
                  <div
                    id="divscroll"
                    className=""
                  >
                    <div className="d-flex px-3">
                      <div className="col-4 jbscroll pe-2">
                        {jobcards.map((item, index) => {
                          return (
                            <div key={index}>
                              {!cardshimmer ? (
                                <Card
                                  data={item}
                                  key={index}
                                  border={border}
                                  tempId={setSelectedJob}
                                  selected={selectedJob}
                                  handleJobCardClick={setSelectedJob}
                                />
                              ) : (
                                <Jobapplyshimmer />
                              )}
                            </div>
                          );
                        })}
                        <div className="my-3 d-flex justify-content-center">
                          <Pagination
                            count={totalPages}
                            page={page}
                            color="primary"
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>

                      <div
                        className="col-8 px-3 "
                        // style={{ left: '36%' }}
                      >
                        {!descShimmer ? (
                          <JobApply
                            data={selectedJob}
                            roles={roles}
                            company={company}
                            token={token}
                            loader={loader}
                            setLoader={setLoader}
                            applied={handleApply}
                            verified={verified}
                            setVerified={setVerified}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                          />
                        ) : (
                          <Descriptionshimmer />
                        )}
                      </div>
                    </div>
                  </div>
                )
                  :
                  (
                    <Errorpage
                      jobcards={jobcards}
                      border={border}
                      setSelectedJob={setSelectedJob}
                      selectedJob={selectedJob}
                      totalPages={totalPages}
                      page={page}
                      handlePageChange={handlePageChange}
                      roles={roles}
                      company={company}
                      token={token}
                      loader={loader}
                      setLoader={setLoader}
                      handleApply={handleApply}
                      verified={verified}
                      setVerified={setVerified}
                    />
                  )}
              </>
              {document.cookie.split('_secure_ARJ_')[1] ? (
                <FooterZ handleClick={handleFooterClick} />
              ) : (
                <FooterN handleClick={handleFooterClick} />
              )}
            </div>
          )}
        </>
      )}

      {!isSmallScreen && !isMediumScreen && (
        <>
          {loader ? (
            <>
              <Header />
              {document.cookie.split('_secure_ARJ_')[1] ? (
                <FooterZ />
              ) : (
                <FooterN />
              )}
            </>
          ) : (
            <div style={{ background: 'rgb(246, 246, 246)' }}>
              <Homeheader
                skill={skill}
                // setSkill={setSkill}
                // location={location}
                setLocation={setLocation}
              // handleSearch={handleShow}
              />
              <>
                <div
                  className="py-1 sticky-top bg-white mb-3"
                  style={{ top: '55px', zIndex: '1' }}
                >
                  <div
                    className="container col-lg-12 d-flex d-md-flex gap-lg-2 gap-md-2"
                    style={{ boxSizing: 'border-box', letterSpacing: '0.7px' }}
                  >
                    {jobPosted.length > 0 && (
                      <JobPostedFilter
                        name={'Job Posted On'}
                        menuOptions={jobPosted}
                        changeRoles={handleJobPostedChange}
                        reset={handleJobPostedReset}
                        show={handleShow}
                      />
                    )}
                    {jobtypes.length > 0 && (
                      <JobFilters
                        name={'Job Type'}
                        count={jobtypes.filter((e) => e.isSelected).length}
                        menuOptions={jobtypes}
                        changeRoles={handleJobTypeChange}
                        reset={handleJobTypeReset}
                        show={handleShow}
                      />
                    )}
                    {roles.length > 0 && (
                      <JobFilters
                        name={'Job Role'}
                        count={roles.filter((e) => e.isSelected).length}
                        menuOptions={roles}
                        changeRoles={handleRoleChange}
                        reset={handleJobRoleReset}
                        show={handleShow}
                      />
                    )}
                    {company.length > 0 && (
                      <JobFilters
                        name={'Company'}
                        count={company.filter((e) => e.isSelected).length}
                        menuOptions={company}
                        changeRoles={handleCompanyChange}
                        reset={handleCompanyReset}
                        show={handleShow}
                      />
                    )}
                    {
                      workMode.length > 0 && (   
                        <JobFilters
                          name={'Work Mode'}
                          count={workMode.filter((e) => e.isSelected).length}
                          menuOptions={workMode}
                          changeRoles={handleWorkModeChange}
                          reset={handleWorkModeReset}
                          show={handleShow}
                        />
                      )
                    }

                    {(roles.filter((e) => e.isSelected).length > 0 ||
                      jobtypes.filter((e) => e.isSelected).length > 0 ||
                      company.filter((e) => e.isSelected).length) > 0 || 
                      workMode.filter((e) => e.isSelected).length > 0
                      ? (
                      <Button
                        variant="text"
                        size="medium"
                        onClick={() => handleResetAll()}
                        className="px-4 fw-bolder rounded-pill"
                        sx={{ letterSpacing: '2px' }}
                      >
                        Reset all
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                {cookies._secure_ARJ_ || cookies._secure_ref ? (
                  isVerified ? (
                    <></>
                  ) : (
                    <div id="divscroll2" className="container-fluid my-2 mt-0">
                      <AlertShow
                        email={props.email}
                        verified={verified}
                        setAlert={setVerified}
                        title={
                          'Verify your email id to continue applying for jobs.'
                        }
                        status={'error'}
                      />
                    </div>
                  )
                ) : (
                  <> </>
                )}
                {searchResults ? (
                  <div className="container pb-2 text-muted fx fw-bold">
                    Showing Results for{' '}
                    <span className="text-capitalize">
                      " {searchParams.get('search') || skill} "
                    </span>
                  </div>
                ) : (
                  <></>
                )}
                {jobcards.length > 0 ? (
                  <div
                    id="divscroll"
                    className=" jbscroll "
                    style={{ overflowY: 'scroll' }}
                  // style={{ height: "38.5vw", overflowY: "scroll" }}
                  >
                    <div className="container col-lg-12 m-auto d-flex d-md-flex  ">
                      <div className="col-lg-4 col-md-4 col-sm-6  px-0 pe-3">
                        {jobcards.map((item, index) => {
                          return (
                            <div key={index}>
                              {!cardshimmer ? (
                                <Card
                                  data={item}
                                  key={index}
                                  border={border}
                                  tempId={setSelectedJob}
                                  selected={selectedJob}
                                  handleJobCardClick={setSelectedJob}
                                />
                              ) : (
                                <Jobapplyshimmer />
                              )}
                            </div>
                          );
                        })}
                        <div className="my-3 d-flex justify-content-center">
                          <Pagination
                            count={totalPages}
                            page={page}
                            color="primary"
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>

                      <div
                        className="col-lg-7 col-md-8 col-sm-6 px-3 jobApplyDiv"
                        style={{ left: '36%' }}
                      >
                        {!descShimmer ? (
                          <JobApply
                            data={selectedJob}
                            roles={roles}
                            company={company}
                            token={token}
                            loader={loader}
                            setLoader={setLoader}
                            applied={handleApply}
                            verified={verified}
                            setVerified={setVerified}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                          />
                        ) : (
                          <Descriptionshimmer />
                        )}
                      </div>
                    </div>
                  </div>
                )
                  :
                  (
                    <Errorpage
                      jobcards={jobcards}
                      border={border}
                      setSelectedJob={setSelectedJob}
                      selectedJob={selectedJob}
                      totalPages={totalPages}
                      page={page}
                      handlePageChange={handlePageChange}
                      roles={roles}
                      company={company}
                      token={token}
                      loader={loader}
                      setLoader={setLoader}
                      handleApply={handleApply}
                      verified={verified}
                      setVerified={setVerified}
                    />
                  )}
              </>
              {document.cookie.split('_secure_ARJ_')[1] ? (
                <FooterZ handleClick={handleFooterClick} />
              ) : (
                <FooterN handleClick={handleFooterClick} />
              )}
            </div>
          )}
        </>
      )}
     
    </>
  );
}

export default Jobsearchpage;
