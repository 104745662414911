import React from 'react'
import Homeheader from '../components/homeheader'
import FooterN from '../components/FooterN'

const PrivacyPolicy = () => {
  return (
    <div>
      <div className='sticky-top bg-white'>
        <Homeheader />
      </div>
      <div className='container fx'>
        <div style={{fontSize : "48px"}} className='py-3 text-center'>Privacy Policy</div>
        <div className='row pb-5'>
          <div className='row m-0'>
            At Jobeze.com, we are committed to protecting your privacy and safeguarding the personal information you provide to us. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our job portal website. By accessing or using Jobeze.com, you consent to the practices described in this Privacy Policy.
          </div>
          <div className='row'>
            <ul className="list-group list-group-numbered fs-5">
              <li className="list-group-item border-0 fw-bolder">
                <span className=''>Information We Collect</span>
                <ol className='fs-6 fw-normal fw-normal' type='a'>
                  <li>
                      Personal Information: When you create an account or use our services, we may collect personal information such as your name, email address, contact information, resume, and other relevant details.
                  </li>
                  <li>
                    Usage Information: We collect information about your interactions with Jobeze.com, including your IP address, device information, browser type, access times, and pages visited. This information helps us analyze trends, administer the website, and improve our services.
                  </li>
                  <li>
                    Cookies and Similar Technologies: We use cookies and similar technologies to enhance your experience, personalize content, and analyze website traffic. You have the option to disable cookies through your browser settings, but this may affect certain features and functionality of Jobeze.com.
                  </li>
                </ol>
              </li>
              <li className="list-group-item border-0 fw-bolder">
                <span className=''>Use of Information</span>
                <ol className='fs-6 fw-normal' type='a'>
                  <li>
                    Provide and Improve Services: We use the information we collect to provide and improve our services, customize your experience, and optimize job search results. This includes matching job seekers with relevant job listings and facilitating communication between job seekers and employers.
                  </li>
                  <li>
                    Communication: We may use your contact information to send you notifications, updates, and promotional materials related to our services. You can opt-out of receiving promotional communications at any time.
                  </li>
                  <li>
                    Aggregated Data: We may anonymize and aggregate data for statistical and research purposes, which may be shared with third parties. This data does not personally identify individuals.
                  </li>
                </ol>
              </li>
              <li className="list-group-item border-0 fw-bolder">
                <span className=''>Disclosure of Information</span>
                <ol className='fs-6 fw-normal' type='a'>
                  <li>
                    Employers and Job Seekers: To facilitate job searches and applications, certain information such as resumes, profiles, and contact details may be shared with employers or job seekers, depending on your role. Please exercise caution when sharing sensitive or personal information.
                  </li>
                  <li>
                    Service Providers: We may engage trusted third-party service providers to assist with website operation, data analysis, marketing, and other services. These providers have access to personal information solely for the purpose of performing their duties and are bound by confidentiality obligations.
                  </li>
                  <li>
                    Legal Compliance: We may disclose personal information if required by law, regulation, legal process, or government request, or to protect the rights, property, or safety of Jobeze.com, our users, or others.
                  </li>
                </ol>
              </li>
              <li className="list-group-item border-0 fw-bolder">
                <span className=''>Data Security</span>
                <div className='fs-6 fw-normal px-4'>We implement appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information. However, no data transmission over the Internet or electronic storage system can be guaranteed as 100% secure. Please take precautions to protect your personal information.</div>
              </li>
              <li className="list-group-item border-0 fw-bolder">
                <span className=''>Third-Party Links and Services</span>
                <div className='fs-6 fw-normal px-4'>Jobeze.com may contain links to third-party websites or services that are not owned or controlled by us. This Privacy Policy does not apply to such third-party sites. We encourage you to review the privacy policies of those sites before providing any personal information.</div>
              </li>
              <li className="list-group-item border-0 fw-bolder">
                <span className=''>Children's Privacy</span>
                <div className='fs-6 fw-normal px-4'>
                  Jobeze.com is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If you believe that we have inadvertently collected personal information from a child, please contact us immediately.
                </div>
              </li>
              <li className="list-group-item border-0 fw-bolder">
                <span className=''>Changes to this Privacy Policy</span>
                <div className='fs-6 fw-normal px-4'>
                  We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We will notify you of any material changes by posting the updated Privacy Policy on Jobeze.com or through other communication channels. Your continued use of the website after such changes constitutes your acceptance of the revised Privacy Policy.
                </div>
              </li>
              <li className="list-group-item border-0 fw-bolder">
                <span className=''>Contact Us</span>
                <div className='fs-6 fw-normal px-4'>
                  If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at support@jobeze.com.
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <FooterN />
    </div>
  )
}

export default PrivacyPolicy;