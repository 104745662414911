import React from 'react'
import Homeheader from '../components/homeheader'
import FooterN from '../components/FooterN'

const BewareFruad = () => {
  return (
    <div>
      <div className='sticky-top bg-white'>
        <Homeheader />
      </div>
      <div className='container fx'>
        <div style={{ fontSize: "48px" }} className='py-3 text-center'>
          Beware of Fraudsters
        </div>
        <div className='row m-0 pb-5'>
          <div className='row m-0'>
            At Jobeze.com, we are committed to maintaining the integrity of our job portal and ensuring a safe and secure environment for our users. We want to alert you to the presence of fraudsters who may attempt to take advantage of unsuspecting job seekers and employers. It is important to remain vigilant and follow these guidelines to protect yourself from fraudulent activities:
          </div>
          <div className='row'>
            <ul className="list-group list-group-numbered fs-5">
              <li className='list-group-item border-0 fw-bolder'>
                <span>Job Seekers :</span>
                <ol className='fs-6 fw-normal fw-normal' type='a'>
                  <li>Job Listings : Exercise caution when reviewing job listings. Be skeptical of job offers that sound too good to be true or promise high salaries for minimal work. Verify the legitimacy of the employer and the job opening before proceeding.</li>
                  <li>Personal Information : Never provide your personal or financial information, such as your social security number, bank account details, or credit card information, unless you have verified the credibility of the employer and the job opportunity.</li>
                  <li>Payment Requests : Be wary of job offers that require you to make a payment or purchase products or services upfront. Legitimate employers will not ask for money in exchange for a job opportunity.</li>
                  <li>Communication Channels : Use the communication tools provided on Jobeze.com to interact with employers. Be cautious of requests to communicate outside of the platform or through personal email addresses or messaging apps. Genuine employers will respect your privacy and use the official channels provided.</li>
                  <li>Research and Verification : Conduct research on the company or employer before applying or accepting a job offer. Look for reviews, check their website, and verify their contact information. If something seems suspicious or inconsistent, trust your instincts and proceed with caution.</li>
                </ol>
              </li>
              <li className='list-group-item border-0 fw-bolder'>
                <span>Employers :</span>
                <ol className='fs-6 fw-normal fw-normal' type='a'>
                  <li>Candidate Screening : Take the time to thoroughly screen and verify job applicants. Request resumes, conduct interviews, and check references to ensure the legitimacy and qualifications of the candidates.</li>
                  <li>Payment Requests : Do not request job applicants to pay a fee or provide financial information as a condition of employment. Legitimate employers do not charge candidates for job opportunities.</li>
                  <li>Job Offer Confirmation : Once you have made a job offer, verify the identity of the candidate and confirm their acceptance directly through the official channels provided by Jobeze.com. Beware of fraudulent individuals posing as candidates.</li>
                  <li>Protect Sensitive Information : Safeguard sensitive company information and avoid sharing it with unverified individuals. Be cautious of requests for proprietary data or sensitive financial information from potential job applicants.</li>
                </ol>
              </li>
              <li className='list-group-item border-0 fw-bolder'>
                <span>Reporting Fraud :</span>
                <ol className='fs-6 fw-normal fw-normal' type='a'>
                  <li>If you encounter any suspicious activity or believe that you have been a victim of fraud on Jobeze.com, please report it to us immediately. Provide as much detail as possible about the incident, including the user profiles involved, job listings, and any relevant communication.</li>
                  <li>Remember, while Jobeze.com takes measures to detect and prevent fraudulent activities, it is essential for users to remain vigilant and exercise caution throughout the job search and hiring process. Trust your instincts and report any suspicious activity to protect yourself and others from potential fraudsters.</li>
                  <li>Please note that these guidelines are provided to raise awareness and promote a safer job search experience. They do not guarantee the complete elimination of fraudulent activities. It is important for users to stay informed, educate themselves about potential risks, and make informed decisions.</li>
                </ol>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <FooterN />
    </div>
  )
}

export default BewareFruad