import { Button, CircularProgress } from "@mui/material";
import React, { useEffect, useRef } from "react";
import Recommendedjobscard from "./Recommendedjobscard";
import RecommendedSkillscard from "./RecommendedSkillscard";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Recommendedempty from "./Recommendedempty";
import RecommendAIjobs from "./RecommendAIjobs";
import SavedShimmer from "./SavedShimmer";

const RecommendedJobs = ({ id }) => {
  const [countjobs, setcountjobs] = useState(0);
  const [checkjobid, setcheckjobid] = useState([]);
  const [getData, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const scrollContainerRef = useRef(null);
  const [totalCount, setTotalCount] = useState(0);
  const [summit, setsummit] = useState(false);
  const [save, setSave] = useState({});
  const [totalJobs, setTotalJobs] = useState();
  const [selectAll, setSelectAll] = useState(false);

  let currentPage = 1;
  let isLoading = false;
  let more = true;
  // console.log(id, "--------------------");


  const fetchMoreData = async () => {
    if (!more || isLoading) {
      return;
    }

    // Set isLoading to true before making the request
    isLoading = true;
    setLoading(true);
    try {
      const response = await axios.get(
        `recommend-jobs/${localStorage.getItem("id")}/page-no/${currentPage}`,
        // `recommend-jobs/14211/page-no/${currentPage}`,
        {
          headers: { authorization: document.cookie },
        }
      );
      currentPage = currentPage + 1;

      const newData = response.data.message.data;
      setTotalCount(response.data.message.total);
      setTotalJobs(response.data.message.totalJob);

      if (newData.length > 0) {
        // Append new data to the existing data
        setData((prevData) => [...prevData, ...newData]);
        const newSaveData = newData.reduce((acc, job) => {
          acc[job.joborder_id] = job.isSaved;
          return acc;
        }, {});
        setSave((prevSave) => ({ ...prevSave, ...newSaveData }));
        // setData(newData);
      } else {
        setHasMore(false);
        more = false;
      }
      // setData(response.data.message.data);
      // setTotalCount(response.data.message.total);

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Reset isLoading to false after the request is done
      isLoading = false;
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMoreData();
  }, []);

//   const handleCheckall = async () => {
//   // If not all jobs are checked
//   const uncheckedCLJobs = getData.filter((job) => job.cover_letter_required !== 1);
//     const uncheckedQuesJobs = getData.filter((job) => job.questionnaire_required !== 1);
//     const uncheckedJobIds = [
//       ...uncheckedCLJobs.map((job) => job.joborder_id),
//       ...uncheckedQuesJobs.map((job) => job.joborder_id)
//     ];

//   // Select up to 7 jobs, excluding those with cover_letter_required === 1
//   const selectedJobIds = uncheckedJobIds.slice(0, 7);

//   // Update the checked job array and count
//   setcheckjobid(selectedJobIds);
//   setcountjobs(selectedJobIds.length);
// };

  const handleCheckall = async () => {
    // Filter jobs based on cover letter and questionnaire requirements
    const uncheckedJobIds = getData.reduce((acc, job) => {
      if (job.cover_letter_required !== 1 && job.questionnaire_required !== 1) {
        acc.push(job.joborder_id);
      }
      return acc;
    }, []);

    // Select up to 7 jobs
    const selectedJobIds = uncheckedJobIds.slice(0, 7);

    // Update the checked job array and count
    setcheckjobid(selectedJobIds);
    setcountjobs(selectedJobIds.length);
    console.log(selectedJobIds, "selectedJobIds");
  };

  const handleUnSave = async (id) => {
    setsummit(true);
    try {
      const response = await axios.post(`user/unsave-job`, {
        token: document.cookie,
        isRecommended: true,
        job_id: id,
      });
      toast.success("Job unsaved successfully", {
        hideProgressBar: true,
        closeOnClick: true,
      });
      setData((prevData) => prevData.filter((e) => e.joborder_id !== id));
      if (getData.length === 0) {
        fetchMoreData();
      }
    }
    catch (error) {
      console.log(error);
    }
    setsummit(false);

    // let tempData = [...getData];
    // tempData = tempData.filter((e) => e.joborder_id !== id);
    // setData([...tempData]);

  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = container.scrollTop;
      const maxScrollHeight = container.scrollHeight - container.clientHeight;
      const threshold = 100;
      if (scrollPosition >= maxScrollHeight - threshold) {
        fetchMoreData();
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);

      // Clean up the event listener when the component unmounts
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  

  function findObjectsById(ids, data) {
    const result = [];
    ids.forEach(id => {
      const found = data.find(item => item.joborder_id === id);
      if (found) {
        result.push({ job: found.joborder_id, original_date_modified: found.date_modified });
      }
    });
    return result;
  }

  const result = findObjectsById(checkjobid, getData);
  // console.log(result, "result");

  const handleBulkapply = async () => {
    try {
      // setcountjobs(0);
      if (checkjobid.length > 7) {
        toast.warning("You can only apply to a maximum of 7 jobs at a time", {
          hideProgressBar: true,
          closeOnClick: true,
        });
        return;
      }

      const response = await axios.post(`user/bulk-applied-quick/job`, {
        token: document.cookie,
        job_ids: result,
        isRecommended: true,
      });
      toast.success("Job applied successfully", {
        hideProgressBar: true,
        closeOnClick: true,
      });

      // If the response is successful, call fetchMoreData immediately
      if (response.status === 200) {
        setcheckjobid([]);
        setcountjobs(0);
        if (!more || isLoading) {
          return;
        }

        // Set isLoading to true before making the request
        isLoading = true;
        setLoading(true);
        if (response.status === 200) {
          setLoading(false);
          setcheckjobid([]);
          setcountjobs(0);
          let tempData = [...getData];

          // Remove the applied jobs from the displayed data
          tempData = tempData.filter((job) => !checkjobid.includes(job.joborder_id));

          setData([...tempData]);
          setTotalJobs((prevTotalJobs) => prevTotalJobs - checkjobid.length);

          // If there are no more jobs to display, fetch more data
          if (tempData.length === 0) {
            fetchMoreData();
            setLoading(false);
          }
        }
      }
    } catch (err) {
      toast.error(err.response.data.error, {
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
  };

  // const handleBulkapply = async () => {
  //   try {
  //     if (checkjobid.length > 7) {
  //       toast.warning("You can only apply to a maximum of 7 jobs at a time", {
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //       });
  //       return;
  //     }

  //     const response = await axios.post(`user/bulk-applied-quick/job`, {
  //       token: document.cookie,
  //       job_ids: checkjobid,
  //       isRecommended: true,
  //     });

  //     toast.success("Job applied successfully", {
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //     });

  //     // If the response is successful, update the UI accordingly
  //     if (response.status === 200) {
  //       setcheckjobid([]);
  //       setcountjobs(0);
  //       let tempData = [...getData];

  //       // Remove the applied jobs from the displayed data
  //       tempData = tempData.filter((job) => !checkjobid.includes(job.joborder_id));

  //       setData([...tempData]);

  //       // If there are no more jobs to display, fetch more data
  //       if (tempData.length === 0) {
  //         fetchMoreData();
  //       }
  //     }
  //   } catch (err) {
  //     toast.error(err.response.data.error, {
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //     });
  //   }
  // };

  const handleSave = async (joborder_id) => {
    if (!document.cookie.split("_secure_ARJ_")[1]) {
      toast.warning("Please login to save", {
        hideProgressBar: true,
        closeOnClick: true,
      });
      return;
    }

    try {
      const currentSaveStatus = save[joborder_id] || false;

      const response = await axios.post(`user/${currentSaveStatus ? 'unsave' : 'save'}-job`, {
        job_id: joborder_id,
        token: document.cookie,
      });

      toast.success(`Job ${currentSaveStatus ? 'unsaved' : 'saved'} successfully`, {
        hideProgressBar: true,
        closeOnClick: true,
      });
      
      setSave(prevStates => ({
        ...prevStates,
        [joborder_id]: !currentSaveStatus, // Toggle the save state
      }));


      // saveStatus(prevStates => ({
      //   ...prevStates,
      //   [joborder_id]: !currentSaveStatus, // Toggle the save state
      // }));
    } catch (error) {
      console.error("Error saving/unsaving job:", error);
      // Handle error, show a toast or perform other actions as needed
    }
  };
  // const handleSaveall = async () => {
  //   try {
  //     const jobsToSave = getData.slice(0, 7); // Get the first 7 jobs
  //     const savePromises = jobsToSave.map(async (job) => {
  //       await handleSave(job.joborder_id); // Save each job individually
  //     });

  //     await Promise.all(savePromises);

  //     toast.success("Top 7 jobs saved successfully", {
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //     });
  //   } catch (error) {
  //     console.error("Error saving top 7 jobs:", error);
  //     // Handle error, show a toast or perform other actions as needed
  //   }
  // };


  return (
    <div className="p-0" style={{ backgroundColor: "rgb(246, 246, 246)" }}>
      {getData.length > 0 ? (
        <>
          <div className=" py-3  bg-white pb-2 fx">
            <div className=" container d-flex justify-content-between align-items-center">
              <div>
                <p className=" fw-bold m-0">Recommended Jobs ( {totalJobs} )   </p>
              </div>
              <div className="fw-bold " style={{ zIndex: "1" }}>
                <Button size="small" className="fw-bold mx-2" sx={{textTransform: "none"}}  variant="text" onClick={handleCheckall} >Select Top 7 Jobs</Button>
                { countjobs === 0 ? (
                  <Button
                    disabled={countjobs === 0 ? true : false}
                    size="small"
                    className="fw-bold mx-2"
                    variant="contained"
                  >
                    Apply to jobs
                  </Button>
                ) : (
                  <Button
                    disabled={countjobs === 0 ? true : false}
                    onClick={handleBulkapply}
                    size="small"
                    className="fw-bold mx-2"
                    variant="contained"
                  >
                    Apply to {countjobs} {countjobs === 1 ? "job" : "jobs"}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* <div className=" row m-0 justify-content-between jbscroll"> */}
      <div ref={scrollContainerRef} className="jbscrollsave px-3 px-lg-0">
        <div className="p-0">
          <div className="container m-auto row m-0 justify-content-between">
            {loading && (
              <div className="">
                <SavedShimmer />
              </div>
            )}
            {!loading && getData.length > 0
              ? getData.map((data, index) => (
                <div key={index} className="col-lg-12 ps-0 pt-1">
                  <RecommendAIjobs
                    data={data}
                    summit={summit}
                    isSaveJob={true}
                    isRecommended={true}
                    countjobs={countjobs}
                    checkjobid={checkjobid}
                    setcountjobs={setcountjobs}
                    setcheckjobid={setcheckjobid}
                    save={save[data.joborder_id]}
                    onUnsave={(id) => handleUnSave(id)}
                    handleSave={(joborder_id) => handleSave(joborder_id)}
                  />
                </div>
              ))
              : !loading &&
              <Recommendedempty isrecommended={true} />
            }
          </div>
          {loading && (
            <div className="d-flex justify-content-center mt-3">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default RecommendedJobs;
