import "./Login.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import Password from "./components/Password";
import Homeheader from "./components/homeheader";
import axios from "axios";
import GoogleAuth from "./components/GoogleAuth";
import { toast } from "react-toastify";
import Backdrops from "./components/Backdrop";
import { useAppDispatch } from "./redux/hooks";
import styled from "styled-components";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Button from "@mui/material/Button";
import { getRenewal_date, getUser, isResumeVerified, setToken } from "./redux/modules/userSlice";
// import { useSelector } from "react-redux";
import ResendBackdrop from "./ResendBackdrop";


export function Login() {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  

  const { linkedInLogin } = useLinkedIn({
    clientId: `${process.env.REACT_APP_LINKEDIN_URL}`,
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      setIsOpen(true);
      axios
        .post(`user/linkedin-login`, { code: code })
        .then((response) => {
          setIsOpen(false);
          dispatch(getUser(response.data.message.isVerified));
          dispatch(setToken(response.data.message.token));
          dispatch(isResumeVerified(response.data.message.isResume));
          localStorage.setItem("linkedIn", response.data.message.is_linkdn_followed);
          localStorage.setItem("id", response.data.message.result_email.id);
          localStorage.setItem(
            "email",
            response.data.message.result_email.email
          );
          localStorage.setItem("name", response.data.message.result_email.name);
          const d = new Date();
          // d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
          d.setTime(d.getTime() + 1 * 60 * 60 * 1000);
          let expires = "expires=" + d.toUTCString();
          document.cookie =
            "_secure_ARJ_" +
            "=" +
            response.data.message.token +
            ";" +
            expires +
            ";path=/";

          if (response.data.message.comment === "Old User") {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/uploadpage";
          }
        })
        .catch((err) => {
          setIsOpen(false);
          toast.error("Please Try Again!", {
            hideProgressBar: true,
            closeOnClick: true,
          });
        });
    },
    scope: "r_emailaddress r_liteprofile",
    onError: (error) => {
      console.log(error);
    },
  });

  const nav = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [error, setError] = useState("");
  const [logErr, setLogErr] = useState([
    {
      field: false,
      error: "",
    },
    {
      field: false,
      error: "",
    },
  ]);

  const handleSign = () => {
    nav("/signup");
  };
  const handleForget = () => {
    nav("/forgetpassword");
  };
  const handlesection = () => {
    setIsOpen(true);

    if (!email) {
      setIsOpen(false);
      const stateData = [...logErr];
      stateData[0].field = true;
      stateData[0].error = "Please Enter Your Email";
      stateData[1].field = false;
      stateData[1].error = "";
      setLogErr(stateData);
      return;
    }

    if (!regex.test(email)) {
      setIsOpen(false);
      const stateData = [...logErr];
      stateData[0].field = true;
      stateData[0].error = "Please enter a valid email";
      stateData[1].field = false;
      stateData[1].error = "";
      setLogErr(stateData);
      return;
    }
    if (!password) {
      setIsOpen(false);
      const stateData = [...logErr];
      stateData[0].field = false;
      stateData[0].error = "";
      stateData[1].field = true;
      stateData[1].error = "Please Enter Your Password";
      setLogErr(stateData);
      return;
    } else {
      setLogErr([
        {
          field: false,
          error: "",
        },
        {
          field: false,
          error: "",
        },
      ]);
      localStorage.setItem("email", email);
      axios
        .post(`user/portal/login`,  {
          email: email,
          password: password,
        }, {
          withCredentials: true,
        })
        .then((response) => {
          // console.log("response",response)
          // const allCookies = document.cookie;

          // console.log('Cookies:', allCookies);
          // const cookiesArray = document.cookie.split('; ');
          // const refreshTokenCookie = cookiesArray.find(cookie => cookie.startsWith('_secure_ref='));

          // Get the 'Set-Cookie' header from the response
          // const setCookieHeader = headers['set-cookie'];
          // Extract the token from the 'Set-Cookie' header
          // const ref_token = setCookieHeader.split(';')[0].split('=')[1];

          // dispatch(setRefreshToken(ref_token));
          // console.log("ref_token", ref_token);

          // if (refreshTokenCookie) {
          //   const refreshTokenValue = refreshTokenCookie.split('=')[1];
          //   console.log("Refresh Token:", refreshTokenValue);
          //   dispatch(setRefreshToken(refreshTokenValue));
          // } else {
          //   console.log("Refresh Token not found");
          // }
          localStorage.setItem("linkedIn", response.data.message.is_linkdn_followed)
          dispatch(getUser(response.data.message.isVerified));
          dispatch(setToken(response.data.message.token));
          dispatch(isResumeVerified(response.data.message.isResume));
          dispatch(getRenewal_date(response.data.message.renewal_date));
          localStorage.setItem("id", response.data.message.id);
          setIsOpen(false);
          toast.success("Login Successful", {
            hideProgressBar: true,
            closeOnClick: true,
          });

          const d = new Date();
          // d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
          d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
          let expires = "expires=" + d.toUTCString();
          document.cookie =
            "_secure_ARJ_" +
            "=" +
            response.data.message.token +
            ";" +
            expires +
            ";path=/";
            window.location.href = "/dashboard";
        })
        .catch((err) => {
          setIsOpen(false);
          console.log("err", err)
          toast.error(err.response.data.error, {
            hideProgressBar: true,
            closeOnClick: true,
          });
        });
    }
  };

  const handleUpdateEmail = (event) => {
    setEmail(event.target.value);
  };

  const onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      handlesection();
    }
  };

  return (
    <div>
      {isOpen && <ResendBackdrop />}
      <Homeheader />
      <div className="d-flex justify-content-center align-items-center fx" style={{height: "90vh"}} >
        <div className="m-auto mt-5 mx-2">
          <div className="py-4" style={{ boxShadow: "0px 1px 16px rgba(0, 0, 0, 0.16)", borderRadius: "15px", }} >
            <div className="text-center fw-bold fs-4">
              Login
            </div>
            <div className=" w-75 m-auto py-4 ">
              <TextField
                fullWidth
                onKeyDown={onEnterPress}
                id="email"
                label="Email"
                variant="outlined"
                name="email"
                onChange={handleUpdateEmail}
                error={logErr[0].field ? true : false}
              />
              {logErr[0].error && (
                <span
                  style={{ color: "red", fontSize: "12px" }}
                >
                  {logErr[0].error}
                </span>
              )}
              <div className="mt-3">
                <Password
                  kd={onEnterPress}
                  getPassword={setPassword}
                  passWord={password}
                  passval={logErr[1].field}
                />
                {logErr[1].error && (
                  <span
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {logErr[1].error}
                  </span>
                )}
              </div>
              <div className="">
                <a
                  className=""
                  onClick={handleForget}
                  style={{ cursor: "pointer", textDecoration: "none", fontSize : "14px" }}
                >
                  Forgot Password?
                </a>
              </div>
              {/* {error && <span style={{ color: "red" }}>{error}</span>} */}
              <Button
                className="w-100 my-3 fw-bold"
                size="large"
                onClick={handlesection}
                variant="contained"
              >
                LOG IN
              </Button>
              <div className="d-flex align-items-center overflow-hidden my-2">
                <img src="/Vector3.png" alt="error" width="45%" className="" />
                <span className="px-2 fw-bold">Or</span>
                <img src="/Vector3.png" alt="error" width="50%" className="" />
              </div>
              <div className="text-center fw-bold">Login with</div>
              <div className="d-flex justify-content-evenly mt-4 ">
                <div className=" position-relative border rounded-1">
                  {/* <button className="position-relative border-0 bg-transparent"> */}
                    <span className="position-absolute fw-bold" style={{ left: "12%", top: "19%" }}>
                      <img src="/g.png" alt="Google" width="34%" className="img-fluid px-2" />
                      Google
                    </span>
                    <div>
                      <GoogleAuth />
                    </div>
                  {/* </button> */}
                </div>
                <div className="">
                  <Wrapper>
                    <div className="border rounded-1 p-2" onClick={linkedInLogin} style={{ cursor: "pointer" }}>
                      <LinkedInIcon sx={{ color: "#0077b5" }} />
                      <span className="px-2 fw-bold">LinkedIn</span>
                    </div>
                  </Wrapper>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4 align-items-center">
            <div className="px-3 fw-bold">
              Don’t have an account yet?
            </div>
            <div>
              <Button className="fw-bold px-4" variant="contained" onClick={handleSign}>
                Sign Up
              </Button>
              {/* <button className="button4 btn btn-primary rounded-1 fw-bold" onClick={handleSign}>Sign Up</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Wrapper = styled.div`

`;
