import Homeheader from "./homeheader";
import "./Forgetpage.css";
import NewPassword from "./Newpassword";
import ConfirmPassword from "./Confirmpassword";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Backdrops from "./Backdrop";
import { toast } from "react-toastify";
import { useAppDispatch } from "../redux/hooks";
import { getUser } from "../redux/modules/userSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";


export function VerificationPage() {
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  let loc = useLocation();
  const [isPasswordExist, setIsPasswordExist] = useState(false);
  const regexPass = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassError, setNewPassError] = useState("");
  const [confirmPassError, setConfirmPassError] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    axios
      .post(`user/mail-verify/verify-user`, {
        token: loc.pathname.split("/")[4],
      })
      .then((response) => {
        setIsPasswordExist(response.data.message.hasPassword);
        dispatch(getUser(response.data.message.isVerified));
        if (response.data.message.hasPassword) {
          toast.success("Email Verified Sucessfully", {
            hideProgressBar: true,
            closeOnClick: true,
          });
        } else {
        }
      })
      .catch((err) => {

        toast.error(err.response.data.error, {
          hideProgressBar: true,
          closeOnClick: true,
        });
      });
  }, []);

  const handleSave = () => {
    // Reset error messages
    setNewPassError("");
    setConfirmPassError("");

    // Validate new password
    if (!newPassword || !regexPass.test(newPassword)) {
      setNewPassError("Use 8 or more characters with a mix of uppercase and lowercase letters, numbers & symbols. (ex : 22aAA22@)");
      return;
    }

    // Validate confirm password
    if (newPassword !== confirmPassword) {
      setConfirmPassError("Passwords do not match.");
      return;
    }

    // Save the password or perform any other necessary actions
    // For example, you can make an API call to update the user's password
    // using axios.post('/api/update-password', { newPassword });

    // If the password saving is successful, you can navigate to the home page
    axios
      .post(`user/reset-password/verify-user`, {
        token: loc.pathname.split("/")[4],
        password: newPassword === confirmPassword ? newPassword : "",
      })
      .then((response) => {
        if (response.data.status === 201) {
          localStorage.clear();
          const d = new Date();
          let expires = "expires=" + d.toUTCString();
          document.cookie = "_secure_ARJ_" + "=" + "" + ";" + expires + ";path=/";
          document.cookie = "_secure_ref" + "=" + "" + ";" + expires + ";path=/";
          document.cookie = "cookiesAccepted" + "=" + "" + ";" + expires + ";path=/";
          toast.success(response.data.message, {
            hideProgressBar: true,
            closeOnClick: true,
          });
          setTimeout(() => {
            window.location.href = "/login";
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.error, {
          hideProgressBar: true,
          closeOnClick: true,
        });
      });
  };



  const handleHome = () => {
    nav("/dashboard");
  };

  return (
    <>
      <Homeheader />
      {/* {isOpen && <Backdrops />} */}
      <div
        className="d-flex justify-content-center align-items-center fx"
        style={{ height: "89vh" }}
      >
        <div
          className="bg-white p-5 rounded-3"
          style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)" }}
        >
          <div className="text-center">
            <div className="pb-3">
              <img
                className="img-fluid"
                src="/verified-tick.svg"
                alt="Email-Verified"
                style={{ width: "95px", height: "95px" }}
              />
            </div>
            <div className="h3 fw-bold py-2">Email verified successfully</div>


            {
              isPasswordExist ? (
                <div className=" py-3">
                  <Button
                    className="fw-bold"
                    onClick={handleHome}
                    variant="contained"
                  >
                    Back to Home
                  </Button>
                </div>
              ) : (
                <div>
                  <div className="h6 text-muted py-2 pt-0">
                    Create a password to secure your account.
                  </div>
                  <div className="d-flex flex-column gap-2 py-2">
                    <TextField
                      type={showNewPassword ? "text" : "password"}
                      label="New Password"
                      variant="outlined"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      error={Boolean(newPassError)}
                      helperText={newPassError}
                      sx={{ width: "38ch" }}
                      onFocus={() => setNewPassError("")}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSave();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowNewPassword(!showNewPassword)}
                              edge="end"
                            >
                              {showNewPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      type={showConfirmPassword ? "text" : "password"}
                      label="Confirm Password"
                      variant="outlined"
                      sx={{ width: "38ch" }}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onFocus={() => setConfirmPassError("")}
                      error={Boolean(confirmPassError)}
                      helperText={confirmPassError}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSave();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <Button
                    className="w-100"
                    onClick={handleSave}
                    variant="contained"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSave();
                      }
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
