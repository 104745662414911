import axios from "axios";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import Button from '@mui/material/Button';
import { CircularProgress, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from "../redux/hooks";
import { isResumeVerified } from "../redux/modules/userSlice";

const Chooseresume = ({ setEffectDependency, handleReplace, pathResume, setPathResume }) => {
  const dispatch = useAppDispatch();
  const [dragOver, setDragOver] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    const file = e.dataTransfer.files[0];
    handleFileUpload(file);
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    handleFileUpload(file);
  };

  const handleFileUpload = (file) => {
    if (!file) {
      return;
    }

    // const allowedExtensions = ["pdf", "docx", "doc", "txt"];
    const allowedExtensions = ["pdf", "docx", "txt"];
    const maxSize = 6 * 1024 * 1024;
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      toast.error("Please upload a Pdf, Docx or Txt file", {
        hideProgressBar: true,
        closeOnClick: true,
      });
      return;
    }

    // check the size 
    if (file.size > maxSize) {
      toast.error("File size exceeds the limit of 6 MB", {
        hideProgressBar: true,
        closeOnClick: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("candidate_id", localStorage.getItem("id"));
    formData.append("resume", file);

    const token = document.cookie; // Assuming the token is stored in the cookie

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };

    setEffectDependency(true);
    setLoading(true);
    axios
      .post(`user-resume-upload`, formData, { headers })
      .then((response) => {
        setEffectDependency(false);
        dispatch(isResumeVerified(1))
        setLoading(false);
        toast.success("Resume uploaded successfully", {
          hideProgressBar: true,
          closeOnClick: true,
        });
        // console.log(response);
      })
      .catch((error) => {
        // Handle error
        setLoading(false);
        console.error(error);
      });
  };
  const fileInput = useRef(null);
  const handleUploadClick = (event) => {
    // Trigger the hidden file input to open the file picker dialog
    fileInput.current.click();
  };
  return (
    <>
      {
        loading ?
          (
            <div className={` text-center borderInputMain position-relative py-4 ${dragOver ? "dragOver" : ""}`}>
              <CircularProgress />
            </div>
          )
          :
          <div
            className={`borderInputMain position-relative py-2 ${dragOver ? "dragOver" : ""}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >

            <div className="d-flex flex-column gap-3" style={{ cursor: "pointer" }}>
              {pathResume && (
                <div className="position-absolute end-0 top-0">
                  <IconButton onClick={() => handleReplace()} aria-label="delete">
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
              <div className="d-flex align-items-center justify-content-center gap-2">
                <div>
                  <img src="/Vector.png" className="img-fluid"  alt="Drag and drop" />
                </div>
                <div className=" fw-bold text-muted">
                  Drag and drop file here Or
                </div>
              </div>

              <div className="m-auto">
                <input
                  type="file"
                  ref={fileInput}
                  style={{ display: 'none' }}
                  onChange={handleChange}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  className="fw-bold"
                  onClick={handleUploadClick}
                >

                  Upload
                </Button>
              </div>
            </div>
          </div>
      }
    </>
  );
};

export default Chooseresume;
