import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Backdrops from './Backdrop'
import Button from '@mui/material/Button';
import { Helmet } from "react-helmet";
import { TextField } from '@mui/material'
const FooterN = ({ handleClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  // const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const regex = /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,})+$/;
  const baseURL = process.env.REACT_APP_BASEURL;

  const locations = useLocation();
  // console.log("locations", locations);

  function errorHandler(status) {

    return setError(status)
  }

  const productEngineers = [
    'Embedded Engineer',
    'Network Engineer',
    'Test Engineer'
  ]

  const digitalEngineering = [
    'Artificial Intelligence',
    'Cyber Security',
    'Data Analyst',
    'Data Engineer',
    'Machine Learning',
    'QA Engineer',
    'Security Engineer',
    'Full Stack Developer',
    'Program Manager'
  ]

  const management = [
    'Business Analyst',
    'Product Manager',
    'Project Manager'
  ]

  const nonTech = [
    'Administrator',
    'HR',
    'Mechanical Engineer',
    'Technician',
  ]
  const nav = useNavigate();
  const handleprivacy = () => {
    nav('/privacypolicy');
    window.scrollTo(0, 0);
  }
  const handleTerms = () => {
    nav('/termscondition');
    window.scrollTo(0, 0);
  }
  const handleBeware = () => {
    nav('/bewarefruad');
    window.scrollTo(0, 0);
  }
  const handlegrpr = () => {
    nav('/gdpr');
    window.scrollTo(0, 0);
  }
  const handlesecurity = () => {
    nav('/securitycheaker');
    window.scrollTo(0, 0);
  }
  const handleAbout = () => {
    nav('/about');
    window.scrollTo(0, 0);
  }

  const handlecontact = () => {
    nav('/contactus')
    window.scrollTo(0, 0);
  }
  const handleHtmlSitemap = () => {
    nav('/sitemaphtml')
    window.scrollTo(0, 0);
  }
  const changeHandler = (e) => {
    setEmail(e.target.value)
    errorHandler(false)
  }

  const onSubscribeHandler = async () => {
    try {
      if (!email) {
        return errorHandler(true);
      }
      if (!regex.test(email)) {
        return errorHandler(true);
      } else {
        setIsOpen(true);
        const { data, status } = await axios.post(`subscribe/user`, {
          email
        });
        // console.log(data);
        if (status !== 200) {
          setEmail('');
          toast.error('Please Try Again', { hideProgressBar: true, closeOnClick: true });
          setIsOpen(false);
        } else {
          if (data.message === "user already subscribed") {
            setEmail('');
            toast.warning('You are already subscribed!', { hideProgressBar: true, closeOnClick: true });
          } else {
            toast.success('Subscribed Successfully!', { hideProgressBar: true, closeOnClick: true });
          }
          setEmail('');
          setIsOpen(false);
        }
      }
    } catch (error) {
      toast.error(error.response.data.error, { hideProgressBar: true, closeOnClick: true });
      setIsOpen(false);
    }
  };



  const handleSearch = (skill) => {
    const param_title = skill
    if (locations.pathname.includes("jobsearch")) handleClick(skill);
    // else nav(`/jobsearch/allsearchdata?jobRole=${param_title}&search=${param_title}`, { state: { skill, param_title } });
  }

  

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "https://platform.linkedin.com/in.js";
  //   script.type = "text/javascript";
  //   script.async = true;
  //   script.innerHTML = 'lang: en_US';
  //   document.getElementsByTagName('head')[0].appendChild(script);
  // }, []);


  return (
    <>
      {isOpen && <Backdrops />}
      <div className='footerMain fx'>
        <Helmet>
          <meta title='Find Your Dream Job with Jobeze.com' name="JobSearch" content="Discover the perfect job opportunity with Jobeze.com, the ultimate platform for job seekers. Explore a wide range of career options, browse through thousands of job listings, and take the first step towards your dream job today. Start your job search with Jobeze.com and unlock a world of exciting possibilities. Click here now!" />
          <meta title='Explore Job Listings on Jobeze.com' name="jobListings" content="Looking for the latest job listings? Visit Jobeze.com and explore a vast database of employment opportunities. Discover positions across various industries and locations. Find detailed job descriptions, required qualifications, and apply directly through Jobeze.com. Start your job hunt today! Click to browse now!" />
          <meta title='Craft an Impressive Resume with Jobeze.com' name="resumeBuilder" content="Enhance your chances of landing your dream job with Jobeze.com's powerful resume builder. Create a professional and customized resume that highlights your skills and experiences. Stand out from the competition and make a lasting impression. Get started on Jobeze.com now! Click here to build your resume!" />
          <meta title='Master the Art of Writing Cover Letters' name="coverLetter" content="Want to write an attention-grabbing cover letter? Jobeze.com offers valuable tips and guidance on crafting compelling cover letters. Learn how to showcase your strengths and impress potential employers. Elevate your application with Jobeze.com's expert advice. Click here to boost your cover letter writing skills!" />
          <meta title='Ace Your Job Interviews with Jobeze.com' name="interviewTips" content="Prepare for your upcoming job interviews like a pro with Jobeze.com's comprehensive tips and techniques. Learn how to answer common interview questions, make a positive impression, and increase your chances of success. Gain confidence and land your dream job. Click here to become an interview expert!" />
          <meta title='Navigate Your Career Path with Jobeze.com' name="careerAdvice" content="Unsure about your career path? Jobeze.com offers expert career advice and guidance to help you make informed decisions. Discover various industries, learn about job prospects, and find the right path for your aspirations. Take the next step towards a rewarding career. Click here for valuable insights!" />
          <meta title='Stay Informed About Job Market Trends' name="jobMarket" content="Stay updated with the latest job market trends and insights. Jobeze.com provides comprehensive information on emerging industries, in-demand skills, and market dynamics. Stay ahead of the competition and make informed career decisions. Click here to stay informed!" />
          <meta title='Unlock Success with Jobeze.com Application Tips' name="applicationTips" content="Enhance your job application strategy with Jobeze.com's expert tips. Learn how to create compelling resumes, write captivating cover letters, and stand out from other applicants. Maximize your chances of success. Click here to boost your job application!" />
          <meta title='Discover Remote Job Opportunities on Jobeze.com' name="remoteJobs" content="Explore a wide range of remote job opportunities with Jobeze.com. Find flexible work options that allow you to work from anywhere. Discover remote positions in various industries and embark on a location-independent career. Click here to find your remote dream" />
        </Helmet>
        <div className="container footerMainSection " >
          <div className='row m-0'>
            <div className='col-12 logoinMobile '><img className='img-fluid' alt='Jobeze' src='/Logo.svg' /></div>
            <div className='col-lg-6 d-lg-flex flex-column justify-content-center  '>
              <div className='h5 fw-bold'>Get latest Jobs in your inbox</div>
              <div className='fw-bold text-muted'>Subscribe to our job alerts</div>
            </div>
            <div className='col-lg-6 '>
              <div className='d-lg-flex pb-3 py-lg-0 pt-2 gap-3 text-start justify-content-lg-end'>
                <div className='w-100 Subscribing'>
                  <TextField
                    placeholder='Write Email Address Here'
                    type='email'
                    value={email}
                    onChange={(e) => changeHandler(e)}
                    fullWidth
                    size='small'
                    error={error}
                    className=''
                    sx={{ marginBottom: "15px" }}
                    helperText={error ? "Enter valid email address" : ""}
                  />
                </div>
                <div>
                  <Button className='fw-bold' fullWidth sx={{ height: "40px" }} onClick={() => onSubscribeHandler()} variant="outlined">SUBSCRIBE</Button>
                </div>
              </div>
              <span className='fw-bold Subscribing'>By subscribing, you agree to our <a className='' href="/privacypolicy"> Privacy Policy</a> & <a className='' href="/termscondition">Terms & Conditions</a></span>
            </div>
          </div>
          <div className="row mt-5">
            <div className='col-lg-2 logoinDesktop '><img className='img-fluid' alt='Jobeze' src='/Logo.svg' /></div>
            <div className='col-lg-10 links-section'>
              <div className=''>
                <ul className='ul-list'  >
                  <li className='common-font pb-4'>Product Engineering</li>
                  {
                    productEngineers.map((e, index) => {
                      return <Link key={index} style={{ textDecoration: 'none' }} to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`} onClick={() => handleSearch(e)} ><li className='common-font-2 py-1 pb-2' >{e}</li></Link>
                    })
                  }
                </ul>
              </div>
              <div>
                <ul className='ul-list' >
                  <li className='common-font pb-4'>Digital Engineering</li>
                  {
                    digitalEngineering.map((e, index) => {
                      return <Link key={index} style={{ textDecoration: 'none' }} to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`} onClick={() => handleSearch(e)} ><li className='common-font-2 py-1 pb-2' >{e}</li></Link>
                    })
                  }
                </ul>
              </div>
              <div>
                <ul className='ul-list' >
                  <li className='common-font pb-4'>Management</li>
                  {
                    management.map((e, index) => {
                      return <Link key={index} style={{ textDecoration: 'none' }} to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`} onClick={() => handleSearch(e)} ><li className='common-font-2 py-1 pb-2' >{e}</li></Link>
                    })
                  }

                </ul>
              </div>
              <div>
                <ul className='ul-list'>
                  <li className='common-font pb-4'>Non Tech</li>
                  {
                    nonTech.map((e, index) => {
                      return <Link key={index} style={{ textDecoration: 'none' }} to={`/jobsearch/allsearchdata?search=${e}&jobRole=${e}&location=${"World Wide"}`} onClick={() => handleSearch(e)} ><li className='common-font-2 py-1 pb-2' >{e}</li></Link>
                    })
                  }
                </ul>
              </div>
              <div>
                <ul className='ul-list'>
                  <li className='common-font'>Jobeze</li>
                  <li className='common-font-2' style={{ paddingTop: '24px' }} onClick={handleAbout}>About Us</li>
                  <li className='common-font-2' style={{ paddingTop: '16px' }} onClick={handlecontact}>Contact us</li>
                  <li className='common-font-2' style={{ paddingTop: '16px' }}><a className='text-decoration-none text-dark' href='/signup'>Upload Resume</a></li>
                  <li className='common-font-2' style={{ paddingTop: '16px' }} onClick={handleHtmlSitemap} >HTML Sitemap</li>
                  <li className='common-font-2' style={{ paddingTop: '16px' }}><a className='text-decoration-none text-dark' href='/sitemap-index.xml'>XML Sitemap</a></li>
                </ul>
              </div>
              <div>
                <ul className='ul-list'>
                  <li className='common-font'>Legal</li>
                  <li className='common-font-2' style={{ paddingTop: '24px' }} onClick={handleprivacy}>Privacy Policy</li>
                  <li className='common-font-2' style={{ paddingTop: '16px' }} onClick={handleTerms}>Terms of Service</li>
                  <li className='common-font-2' style={{ paddingTop: '16px' }} onClick={handleBeware}>Beware of Fraudsters</li>
                  <li className='common-font-2' style={{ paddingTop: '16px' }} onClick={handlegrpr}>GDPR EU Cookies</li>
                  <li className='common-font-2' style={{ paddingTop: '16px' }} onClick={handlesecurity}>Security Center</li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="spreation-line"></div> */}
          <hr />
          <div className=" d-lg-flex justify-content-between pt-4 container-sm " >
            <div className="footer-end-col1">
              © 2023 Jobeze. All rights reserved.
            </div>
            <div className="d-flex socialicons" style={{ gap: '15px', marginRight: "45px" }}>
              <a href="https://www.facebook.com/people/Jobeze/100092156896675/" target='_blank' rel='noreferrer' ><img src="/facebook.svg" alt="" /></a>
              <a href="https://www.instagram.com/jobeze_job/ " target='_blank' rel='noreferrer' ><img src="/insta.svg" alt="" /></a>
              <a href=" https://twitter.com/jobeze_jobs" target='_blank' rel='noreferrer' ><img src="/twitter.svg" alt="" /></a>
              {/* <div className='d-flex gap-2 justify-content-center position-relative overflow-hidden'>
                <a target='_blank' rel='noreferrer'>
                  <img src="/linkdin.svg" alt="" />
                </a>
                <div style={{ position:"absolute" , top:"0", opacity:"0"  }}>
                  <script type="IN/FollowCompany" data-id="93251773"  data-counter="bottom"></script>
                </div>
              </div> */}
              <a href="https://www.linkedin.com/company/jobeze/?isFollowingPage=true" target='_blank' rel='noreferrer'><img src="/linkdin.svg" alt="" /></a>
            </div>
          </div>

        </div>

      </div>
    </>
  )
}

export default FooterN