import React, { useEffect, useState } from 'react';
import Homeheader from '../components/homeheader';
import FooterN from '../components/FooterN';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SiteMaphtml(props) {

    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const allCategoryApiHandler = async () => {
    const { data } = await axios.get(`all-category`)
    // console.log(data, "html dta")
    const tempCategories = data.message.category.map((e) => {
        return {
            name: e.name,
            isSelected: false  
        }
    })
        setCategories(tempCategories);
    }
    const firstList = categories.slice(0, 15);
    const secondList = categories.slice(15, 30);
    const thirdList = categories.slice(30, 45);
    const fourthList = categories.slice(45, 60);
    const fifthList = categories.slice(60, 75);
    const sixthList = categories.slice(75, 100);

    const CategoryClickHandler = (item) => {
        const updatedCategories = categories.map((category) => {
            if (category.name === item) {
                return { ...category, isSelected: true }; // Update isSelected to true for the clicked category
            }
            return item;
        });
        setCategories(updatedCategories);        
        navigate(`/jobsearch/allsearchdata?jobRole=${item}`, { state: { category: updatedCategories } })
    };
    
    useEffect(() => {
        allCategoryApiHandler()
    }, [])

    return (
        <div className='fx'>
            <div className='sticky-top bg-white'>
                <Homeheader />
            </div>
            <div className=' text-center'>
                <div style={{ color: "#505050", background: "#F5F5F5"  }} className='col-lg-12 pt-3 py-2 rounded text-uppercase'>
                    <h1 style={{ fontWeight: "700" }}>Sitemap</h1>
                </div>
            </div>
            <div className='container'>
                <div className='row py-1'>
                    <div className='col-lg-12 row'>
                        <div className='col-lg-4 col-md-8 '>
                            <div className='col-lg-12 py-3'>
                                <div className='fw-bold fs-5'>For Job Seekers</div>
                            </div>
                            <div className='col-lg-12 row lead fs-6 m-0'>
                                <div className='col-lg-6 col-md-6'>
                                    <ul className='list-group JobSeekerList'>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/'>Home</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/jobsearch/allsearchdata'>Find Jobs</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/about'>About Jobeze</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/contactus'>Contact Us</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/privacypolicy'>Privacy Policy</a></li>

                                    </ul>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <ul className='list-group JobSeekerList'>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/termscondition'>Terms of Service</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/gdpr'>GDPR EU Cookies</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/securitycheaker'>Security Center</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/bewarefruad'>Be Safe</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 '>
                            <div className='col-lg-12 py-3'>
                                <div className='fw-bold fs-5'>Search Jobs</div>
                            </div>
                            <div className='row lead fs-6 m-0'>
                                <div className='col-lg-12'>
                                    <ul className='list-group JobSeekerList'>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/jobsearch/allsearchdata'>Quick Search</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/jobsearch/allsearchdata'>International Jobs</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/jobsearch/allsearchdata'>Jobeze Jobs</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2  col-md-8 '>
                            <div className='col-lg-12 py-3'>
                                <div className='fw-bold fs-5'>For Users</div>
                            </div>
                            <div className='col-lg-12 row lead fs-6 m-0'>
                                <div className='col-lg-12'>
                                    <ul className='list-group JobSeekerList'>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/login'>User Login</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/forgetpassword'>Forget Password</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/contactus'>Contact Us</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/privacypolicy'>Privacy Policy</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/termscondition'>Terms of Service</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4  col-md-4'>
                            <div className='col-lg-12 py-3'>
                                <div className='fw-bold fs-5'>User Registration</div>
                            </div>
                            <div className='col-lg-12 row lead fs-6 m-0'>
                                <div className='col-lg-7'>
                                    <ul className='list-group JobSeekerList'>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/signup'>User Sign Up</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/uploadpage'>Upload Resume</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/skillpage'>Add Skills</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/profilesection'>User Profile</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/jobsearch/allsearchdata'>Find Jobs</a></li>

                                    </ul>
                                </div>
                                <div className='col-lg-5'>
                                    <ul className='list-group JobSeekerList'>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/contactus'>Contact Us</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/privacypolicy'>Privacy Policy</a></li>
                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' href='/bewarefruad'>Be Safe</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12 '>
                            <div className='col-lg-12 py-3'>
                                <hr />
                                <div className='fw-bolder text-center fs-4'>Job By Category</div>
                            </div>
                            <div className='col-lg-12 row lead fs-6 m-0'>
                                <div className='col-lg-2 col-md-6'>
                                    <ul className='list-group d-flex JobSeekerList'>
                                        {
                                            firstList.map((e, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' onClick={() => CategoryClickHandler(e.name)}>{e.name}</a></li>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='col-lg-2  col-md-6'>
                                    <ul className='list-group d-flex JobSeekerList'>
                                        {
                                            secondList.map((e, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' onClick={() => CategoryClickHandler(e.name)}>{e.name}</a></li>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='col-lg-2  col-md-6'>
                                    <ul className='list-group d-flex JobSeekerList'>
                                        {
                                            thirdList.map((e, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' onClick={() => CategoryClickHandler(e.name)}>{e.name}</a></li>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='col-lg-2  col-md-6'>
                                    <ul className='list-group d-flex JobSeekerList'>
                                        {
                                            fourthList.map((e, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' onClick={() => CategoryClickHandler(e.name)}>{e.name}</a></li>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='col-lg-2  col-md-6'>
                                    <ul className='list-group d-flex JobSeekerList'>
                                        {
                                            fifthList.map((e, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' onClick={() => CategoryClickHandler(e.name)}>{e.name}</a></li>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='col-lg-2 col-md-6'>
                                    <ul className='list-group d-flex JobSeekerList'>
                                        {
                                            sixthList.map((e, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <li className='list-group-item border-0'><a className='text-dark text-decoration-none' onClick={() => CategoryClickHandler(e.name)}>{e.name}</a></li>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterN />
        </div>
    );
}

export default SiteMaphtml;