import React, { useEffect, useState } from "react";
import Homeheader from "../components/homeheader";
import { toast } from "react-toastify";
import FooterN from "../components/FooterN";
import axios from "axios";
import Backdrops from "../components/Backdrop";
import Button from "@mui/material/Button";
import FooterZ from "../components/FooterZ";
import ResendBackdrop from "../ResendBackdrop";
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Contactus = () => {
  const token = document.cookie.split("_secure_ARJ_=")[1];
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isChecked, setChecked] = useState(false);
  const [error, setError] = useState({
    name: "",
    email: "",
    message: "",
    phone:""
  });
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const isSubmitEnabled = () => {
    return (
      name.trim() !== "" &&
      email.trim() !== "" &&
      message.trim() !== "" &&
      isChecked
    );
  };

  function errorHandler(key, message) {
    return key === "all"
      ? setError({
        name: "Please Enter Your Name",
        email: "Please Enter Your Email",
        message: "Please Fill The Detail",
        phone : "Enter 10 digits only"
      })
      : setError({ ...error, [key]: message });
  }

  const handlename = (event) => {
    const inputName = event.target.value;
    const onlyLettersRegex = /^[a-zA-Z\s]*$/; // Regex to allow only letters and spaces

    if (onlyLettersRegex.test(inputName)) {
      setName(inputName);
      errorHandler("name", "");
    } else {
      errorHandler("name", "Only letters and spaces are allowed.");
    }
  };

  const handleLastNameChange = (event) => {
    const inputLastName = event.target.value;
    const onlyLettersRegex = /^[a-zA-Z\s]*$/; // Regex to allow only letters and spaces
    if (onlyLettersRegex.test(inputLastName)) {
      setLastName(inputLastName);
    }
  };

  const handleUpdateEmail = (event) => {
    setEmail(event.target.value);
    errorHandler("email", "");
  };

  const handlemessage = (event) => {
    setMessage(event.target.value);
    errorHandler("message", "");
  };

  const handleChecked = () => {
    setChecked(!isChecked);
  };

  const handleUpdatePhone = (event) => {
    setPhoneNumber(event.target.value);
    errorHandler("phone", "");
    if (event.target.value.length > 10) {
      errorHandler("phone", "Enter 10 digits only");
    }
  };


  const handleSubmit = async () => {
    try {
      if (!name && !email && !message) {
        return errorHandler("all", "");
      }
      if (!name) {
        return errorHandler("name", "Please Enter Your name");
      }
      if (!email) {
        return errorHandler("email", "Please Enter Your Email");
      }
      if (!regex.test(email)) {
        return errorHandler("email", "Please Enter A Valid Email");
      }
      if (!message) {
        return errorHandler("message", "Please Fill The Detail");
      }
      if (phoneNumber.length !== 10 && phoneNumber.length !== 0 ){
        return errorHandler("phone", "Enter 10 digits only")
      }
      else {
        setIsOpen(true);
        const { data, status } = await axios.post(`contact-us`, {
          name: name + " " + lastName,
          email: email,
          message: message,
          isChecked: isChecked,
          mobile: phoneNumber ? parseInt(phoneNumber) : "",
        });
        if (status !== 200) {
          toast.error("Please Try Again", {
            hideProgressBar: true,
            closeOnClick: true,
          });
          setIsOpen(false);
        } else {
          toast.success("Thank you for contacting us! Have a great day!", {
            hideProgressBar: true,
            closeOnClick: true,
          });
          setIsOpen(false);
          setEmail("");
          setMessage("");
          setChecked("");
          setName("");
          setLastName("");
          setPhoneNumber("");
        }
      }
    } catch (error) {
      toast.error(error.response.data.error, {
        hideProgressBar: true,
        closeOnClick: true,
      });
      setIsOpen(false);
    }
  };

  const isSmallScreen = useMediaQuery("(max-width: 625px)");

  return (
    <div>
      <div className="sticky-top bg-white">
        <Homeheader />
      </div>
      {isOpen && <ResendBackdrop />}
      {isSmallScreen ? (
        <div className="container-fluid p-0">
          <div className="p-3">
            <img className="img-fluid w-100" src="/contactusBg.jpg" alt="" />
            <div className="bg-white rounded-3 d-flex flex-column py-2">
              <div className="row m-0 align-items-center">
                <div className="col-lg-7 px-lg-5 py-2">
                  <div className="">
                    <h2 className="fw-bold">Get in Touch</h2>
                    <p className="fw-bold"> We'd love to hear from you! </p>
                  </div>
                  <div className="">
                    <form className="d-flex gap-3 flex-column ">
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        placeholder="First Name"
                        className="input-contact-us"
                        value={name}
                        autoComplete="off"
                        autoFocus
                        type="text"
                        onChange={handlename}
                      />
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Last Name"
                        size="small"
                        className="input-contact-us"
                        value={lastName}
                        autoComplete="off"
                        type="text"
                        onChange={handleLastNameChange}
                      />
                      <TextField
                        className="input-contact-us"
                        fullWidth
                        size="small"
                        placeholder="Enter Email"
                        id="outlined-basic"
                        variant="outlined"
                        value={email}
                        autoComplete="off"
                        error={Boolean(error.email)}
                        helperText={error ? error.email : ""}
                        required
                        onChange={handleUpdateEmail}
                      />
                      <TextField
                        fullWidth
                        id="outlined-textarea"
                        variant="outlined"
                        placeholder="Mobile Number"
                        size="small"
                        autoComplete="off"
                        value={phoneNumber}
                        InputProps={{
                          className: "px-0",
                          startAdornment: (
                            <InputAdornment position="start">
                              <div className="fw-bold ps-2">+ 1</div>
                            </InputAdornment>
                          ),
                          inputProps: {
                            maxLength: 10,
                            onKeyPress: (e) => {
                              const charCode = e.which ? e.which : e.keyCode;
                              if (
                                charCode > 31 &&
                                (charCode < 48 || charCode > 57)
                              ) {
                                e.preventDefault();
                              }
                            },
                            pattern: "[0-9]*",
                          },
                        }}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      <TextField
                        id="outlined-multiline-flexible"
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Message"
                        className=""
                        autoComplete="off"
                        value={message}
                        onChange={handlemessage}
                        error={Boolean(error.message)}
                        helperText={error ? error.message : ""}
                      />
                      <div className="">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onClick={handleChecked}
                              checked={isChecked}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              className="fw-bold text-muted"
                            >
                              {" "}
                              I accept the{" "}
                              <a href="./termscondition">Terms & Conditions</a>.
                            </Typography>
                          }
                        />
                      </div>
                      <div className="pb-3">
                        {isChecked ? (
                          <Button
                            variant="contained"
                            className="px-4 fw-bold"
                            onClick={() => handleSubmit()}
                            disabled={!isSubmitEnabled()}
                          >
                            SUBMIT
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            className="px-4 fw-bold"
                            disabled={!isSubmitEnabled()}
                          >
                            SUBMIT
                          </Button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5 newBgcolor rounded-3 d-lg-flex flex-lg-column gap-4 align-items-center p-4">
                  <div className="fw-bold">
                    <h3 className="fw-bold">Contact Details</h3>
                    <p className="text-muted">
                      Reach out to us for any query, or collaborations.
                    </p>
                  </div>
                  <div className="d-flex flex-column gap-4 py-4">
                    <div className="fw-bold d-flex gap-3">
                      <div className="fw-bold">
                        <EmailIcon />
                      </div>
                      <p className="text-muted Emailaddress-contact-us">
                        <a
                          className="text-decoration-none text-dark"
                          href="mailto:support@jobeze.com"
                        >
                          support@jobeze.com
                        </a>
                      </p>
                    </div>
                    <div className="fw-bold d-flex gap-3">
                      <div className="fw-bold">
                        <PhoneIcon />
                      </div>
                      <p className="text-muted Emailaddress-contact-us">
                        <a
                          className="text-decoration-none text-dark"
                          href="tel:+14085929885"
                        >
                          {"+ 1 (408) 592 9885"}
                        </a>
                      </p>
                    </div>
                    <div className="fw-bold d-flex gap-3">
                      <div className="fw-bold">
                        <LocationOnIcon />
                      </div>
                      <p className="text-muted Emailaddress-contact-us">
                        <a
                          className="text-decoration-none text-dark"
                          target="_blank"
                          href="https://www.google.com/maps/place/1525+McCarthy+Blvd+%231039,+Milpitas,+CA+95035,+USA/@37.4064661,-121.9200217,17z/data=!3m1!4b1!4m6!3m5!1s0x808fc940bc955555:0x21b90e989446e7fc!8m2!3d37.4064661!4d-121.9200217!16s%2Fg%2F11qp4dbk9g?entry=ttu"
                          rel="noreferrer"
                        >
                          {"1525 McCarthy Blvd, Milpitas, CA – 95035"}
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="text-start w-100 ms-4 py-1">
                    <p className="fw-bold">Follow us on </p>
                    <div className="iconsgap">
                      <a
                        href="https://www.facebook.com/people/Jobeze/100092156896675/"
                        style={{ marginLeft: "0" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FacebookIcon
                          fontSize="medium"
                          sx={{ color: "rgba(66, 65, 65, 1)" }}
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/jobeze_job/ "
                        target="_blank"
                        rel="noreferrer"
                      >
                        <InstagramIcon
                          fontSize="medium"
                          sx={{ color: "rgba(66, 65, 65, 1)" }}
                        />
                      </a>
                      <a
                        href=" https://twitter.com/jobeze_jobs"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TwitterIcon
                          fontSize="medium"
                          sx={{ color: "rgba(66, 65, 65, 1)" }}
                        />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/jobeze/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LinkedInIcon
                          fontSize="medium"
                          sx={{ color: "rgba(66, 65, 65, 1)" }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid p-0">
          <div className="contactusImage p-5">
            <div className="bg-white rounded-4 p-1 d-flex flex-column">
              <div className="row m-0 align-items-center p-1">
                <div
                  className="col-lg-5 newBgcolor rounded-4 d-lg-flex flex-lg-column gap-5 align-items-center"
                  style={{ padding: "80px 100px 30px 100px" }}
                >
                  <div className="fw-bold">
                    <h2 className="fw-bold">Contact Details</h2>
                    <p className="text-muted">
                      Reach out to us for any query, or collaborations.
                    </p>
                  </div>
                  <div className="d-flex flex-column gap-4 py-3">
                    <div className="fw-bold d-flex gap-3">
                      <div className="fw-bold">
                        <EmailIcon />
                      </div>
                      <p className="text-muted Emailaddress-contact-us">
                        <a
                          className="text-decoration-none text-dark"
                          href="mailto:support@jobeze.com"
                        >
                          support@jobeze.com
                        </a>
                      </p>
                    </div>
                    <div className="fw-bold d-flex gap-3">
                      <div className="fw-bold">
                        <PhoneIcon />
                      </div>
                      <p className="text-muted Emailaddress-contact-us">
                        <a
                          className="text-decoration-none text-dark"
                          href="tel:+14085929885"
                        >
                          {"+ 1 (408) 592 9885"}
                        </a>
                      </p>
                    </div>
                    <div className="fw-bold d-flex gap-3">
                      <div className="fw-bold">
                        <LocationOnIcon />
                      </div>
                      <p className="text-muted Emailaddress-contact-us">
                        <a
                          className="text-decoration-none text-dark"
                          target="_blank"
                          href="https://www.google.com/maps/place/1525+McCarthy+Blvd+%231039,+Milpitas,+CA+95035,+USA/@37.4064661,-121.9200217,17z/data=!3m1!4b1!4m6!3m5!1s0x808fc940bc955555:0x21b90e989446e7fc!8m2!3d37.4064661!4d-121.9200217!16s%2Fg%2F11qp4dbk9g?entry=ttu"
                          rel="noreferrer"
                        >
                          1525 McCarthy Blvd, Milpitas, CA – 95035
                        </a>
                      </p>
                    </div>
                    <div className="text-start py-5">
                      <p className="fw-bold">Follow us on </p>
                      <div className="iconsgap">
                        <a
                          href="https://www.facebook.com/people/Jobeze/100092156896675/"
                          style={{ marginLeft: "0" }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FacebookIcon
                            fontSize="medium"
                            sx={{ color: "rgba(66, 65, 65, 1)" }}
                          />
                        </a>
                        <a
                          href="https://www.instagram.com/jobeze_job/ "
                          target="_blank"
                          rel="noreferrer"
                        >
                          <InstagramIcon
                            fontSize="medium"
                            sx={{ color: "rgba(66, 65, 65, 1)" }}
                          />
                        </a>
                        <a
                          href=" https://twitter.com/jobeze_jobs"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <TwitterIcon
                            fontSize="medium"
                            sx={{ color: "rgba(66, 65, 65, 1)" }}
                          />
                        </a>
                        <a
                          href="https://www.linkedin.com/company/jobeze/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <LinkedInIcon
                            fontSize="medium"
                            sx={{ color: "rgba(66, 65, 65, 1)" }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 px-lg-5">
                  <div className="py-1">
                    <h2 className="fw-bold">Get in Touch</h2>
                    <Typography gutterBottom className="fw-bold"> We'd love to hear from you! </Typography>
                  </div>
                  <div className="">
                    <form>
                      <Box className="d-flex gap-3 pb-4">
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          placeholder="First Name"
                          className="input-contact-us"
                          value={name}
                          autoComplete="off"
                          autoFocus
                          type="text"
                          onChange={handlename}
                        />
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="Last Name"
                          size="small"
                          className="input-contact-us"
                          value={lastName}
                          autoComplete="off"
                          type="text"
                          onChange={handleLastNameChange}
                        />
                      </Box>
                      <Box className="d-flex gap-3 pb-4">
                        <TextField
                          className="input-contact-us"
                          fullWidth
                          size="small"
                          placeholder="Enter Email"
                          id="outlined-basic"
                          variant="outlined"
                          value={email}
                          autoComplete="off"
                          error={Boolean(error.email)}
                          helperText={error ? error.email : ""}
                          required
                          onChange={handleUpdateEmail}
                        />
                        <TextField
                          fullWidth
                          id="outlined-textarea"
                          variant="outlined"
                          placeholder="Mobile Number"
                          size="small"
                          autoComplete="off"
                          value={phoneNumber}
                          error={Boolean(error.phone)}
                          helperText={error ? error.phone : ""}
                          InputProps={{
                            className: "px-0",
                            startAdornment: (
                              <InputAdornment position="start">
                                <div className="fw-bold ps-2">+ 1</div>
                              </InputAdornment>
                            ),
                            inputProps: {
                              maxLength: 10,
                              onKeyPress: (e) => {
                                const charCode = e.which ? e.which : e.keyCode;
                                if (
                                  charCode > 31 &&
                                  (charCode < 48 || charCode > 57)
                                ) {
                                  e.preventDefault();
                                }
                              },
                              pattern: "[0-9]*",
                            },
                          }}
                          onChange={handleUpdatePhone}
                        />
                      </Box>
                      <Box className="d-flex gap-3 pb-4">
                        <TextField
                          id="outlined-multiline-flexible"
                          multiline
                          rows={4}
                          fullWidth
                          variant="outlined"
                          placeholder="Enter Message"
                          className=""
                          autoComplete="off"
                          value={message}
                          onChange={handlemessage}
                          error={Boolean(error.message)}
                          helperText={error ? error.message : ""}
                        />
                      </Box>
                      <div className="d-flex gap-3">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onClick={handleChecked}
                              checked={isChecked}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              className="fw-bold text-muted"
                            >
                              {" "}
                              I accept the{" "}
                              <a href="./termscondition">Terms & Conditions</a>.
                            </Typography>
                          }
                        />
                      </div>
                      <div className="pt-4">
                        {isChecked ? (
                          <Button
                            variant="contained"
                            className="px-4 fw-bold"
                            onClick={() => handleSubmit()}
                            disabled={!isSubmitEnabled()}
                          >
                            SUBMIT
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            className="px-4 fw-bold"
                            disabled={!isSubmitEnabled()}
                          >
                            SUBMIT
                          </Button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {token ? <FooterZ /> : <FooterN />}
    </div>
  );
};

export default Contactus;
