import React, { useEffect, useState } from "react";
import Header from "./header";
import "./Profilesection.css";
import { blue } from "@mui/material/colors";
import Basic from "./Basic";
import Professional from "./Professional";
import Other from "./Other";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Fouter from "./Fouter";
import axios from "axios";
import {
  Avatar,
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
  toggleButtonClasses,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { green } from "@mui/material/colors";
import FooterZ from "./FooterZ";
import Userprofilecompletion from "./Userprofilecompletion";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Uploadphotoicon from "./../images/uploadphotoicon.png";
import { toast } from "react-toastify";
import Changepassword from "./Changepassword";
import { useAppSelector } from "../redux/hooks";
import { selectUser } from "../redux/modules/userSlice";
import Homeheader from "./homeheader";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Profilesection = () => {
  const nav = useNavigate();
  const [email, setEmail] = useState("");
  const [button1, setbutton1] = useState(false);
  const [button2, setbutton2] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [button3, setbutton3] = useState(true);
  const [result, setResult] = useState();
  const [duplicate,setduplicate] = useState();
  const [toggleChangePasswordPage, setToggleChangePasswordPage] =
    useState(false);
  const [openBasic, setOpenBasic] = useState(true);
  const [openPro, setOpenPro] = useState(false);
  const [openOther, setOpenOther] = useState(false);
  const [completeprofile, setcompleteprofile] = useState("");
  const [replace, setReplace] = useState(false);
  const [effectDependency, setEffectDependency] = useState(false);
  // const [image, setImage] = useState(null);
  const [src, setSrc] = useState(null);
  // const [isback, setIsback] = useState(false);
  const { isVerified } = useAppSelector(selectUser);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [getName, setGetName] = useState("");
  const [value, setValue] = React.useState(0);

  const [date_modified, setDate_modified] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleResend = () => {
    setIsOpen(true);
    axios
      .post(`user/resend-verification/mail`, {
        email: result?.details?.email,
      })
      .then((response) => {
        if (response.status === 200) {
          setIsOpen(false);
          toast.success("Verification link sent to your mail", {
            hideProgressBar: true,
            closeOnClick: true,
          });
        } else {
          setIsOpen(false);
          toast.error("Please try again", {
            hideProgressBar: true,
            closeOnClick: true,
          });
        }
      });
  };

  const handleClick1 = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setbutton1(!button1);
    setOpenBasic(true);
    setOpenPro(false);
    setOpenOther(false);
    if (button2 === false) {
      setbutton2(!button2);
    }
    if (button3 === false) {
      setbutton3(!button3);
    }
  };

  const handleClick2 = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setbutton2(!button2);
    setOpenBasic(false);
    setOpenPro(true);
    setOpenOther(false);
    if (button1 === false) {
      setbutton1(!button1);
    }
    if (button3 === false) {
      setbutton3(!button3);
    }
  };

  const handleClick3 = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setbutton3(!button3);
    setOpenBasic(false);
    setOpenPro(false);
    setOpenOther(true);
    if (button2 === false) {
      setbutton2(!button2);
    }
    if (button1 === false) {
      setbutton1(!button1);
    }
  };

  const handleBack = () => {
    nav("/dashboard");
  };

  const togglePasswordDivHandler = () => {
    if (toggleChangePasswordPage) {
      setToggleChangePasswordPage(false);
    }
  };

  const imageFileHandler = async (e) => {
    var file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // size in MB

      if (fileType !== "image/jpeg" && fileType !== "image/png") {
        setSelectedFile(null);
        setErrorMessage("Only JPEG and PNG files are allowed.");
        return;
      }

      if (fileSize > 2) { // check if file size exceeds 2 MB
        setSelectedFile(null);
        setErrorMessage("File size should not exceed 2 MB.");
        return;
      }

      setSelectedFile(file);
      setErrorMessage("");

      const reader = new FileReader();
      reader.onloadend = function () {
        setSrc(reader.result);
      };
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append("candidate_id", localStorage.getItem("id"));
      formData.append("profile_picture", file);

      const token = document.cookie;

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };

      axios
        .post(`user/profile-picture/upload`, formData, { headers })
        .then((res) => {
          if (res) {
            toast.success("Profile Photo Uploaded Successfully", {
              hideProgressBar: true,
              closeOnClick: true,
              style: {
                width: "350px", // Adjust the width as desired
                right: "50px", // Add margin as desired
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };


  const getData = async () => {
    const { data, status } = await axios.get(`user/dashboard/details`, {
      headers: { authorization: document.cookie },
    });
    const destr_data = { ...data.message }
    // destr_data.details.countryCode = destr_data.details.countryCode.split(" ")[1]
    setDate_modified(data.message.details.date_modified);
    setcompleteprofile(data.message.complete_profile);
    setResult(destr_data);
    setduplicate(destr_data);
    setSrc(data.message.details.profile_path);
    setReplace(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, [effectDependency]);


  useEffect(() => {
    setEmail(result?.details?.email)

  }, [])


  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const [searchParams, setSearchParams] = useSearchParams();
  const [skill, setSkill] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );
  const [location, setLocation] = useState(
    searchParams.get("location") ? searchParams.get("location") : "World Wide"
  );
  const handleSearch = () => {
    setSearchParams({ search: skill, location: location });
    nav(`/jobsearch/allsearchdata?search=${skill}&location=${location}`);
  };


  return (
    <div>
      {/* <Header skill={true} /> */}
      <Homeheader />
      <div
        className="container py-2 "
        style={{ display: "flex" }}
      >
        <div className="d-flex gap-2 align-items-center" onClick={handleBack} style={{ cursor: "pointer" }}>
          <ArrowBackIosNewIcon />
          <div className="fw-bold fs-4 ">My Profile</div>
        </div>
      </div>

      <div className="hide-in-desktop">
        <div className="Profile-section pb-4 fx">
          <div className="Profile-1-box">
            <div className="profile-2-circle rounded-3 " style={{boxShadow: "5px 4px 4px 2px rgba(0, 0, 0, 0.05)"}} >
              <div className="new-circle">
                {src ? (
                  <img
                    className="rounded-circle img-fluid uploaded-image"
                    style={{ width: "135px", height: "135px" }}
                    src={src}
                    alt=""
                  />
                ) : (
                  <Avatar
                    className="avatar"
                    sx={{
                      bgcolor: green[500],
                      width: "135px",
                      height: "135px",
                      fontSize: "45px",
                      fontWeight: "500",
                    }}
                  >
                    {localStorage.getItem("name")?.split("")[0]
                      ? localStorage.getItem("name")?.split("")[0]
                      : "N"}
                  </Avatar>
                )}
                <label htmlFor="upload-input" className="avatar-label">
                  <div className="avatar-overlay">
                    <span className="avatar-text px-2 rounded">REPLACE</span>
                    <input
                      id="upload-input"
                      onChange={imageFileHandler}
                      className="avatar"
                      hidden
                      name="image"
                      accept="image/png,image/jpeg"
                      type="file"
                    />
                  </div>
                </label>
              </div>

              {errorMessage && (
                <p
                  style={{
                    fontSize: "14px",
                    color: "red",
                    textAlign: "center",
                    marginTop: "15px",
                    fontWeight: 600,
                  }}
                >
                  {errorMessage}
                </p>
              )}
              <div className="new-profile-name text-capitalize">
                {/* {getName ? getName : localStorage.getItem("name")} */}
                {getName
                  ? getName?.length > 20
                    ? getName?.substring(0, 20) + "..."
                    : getName
                  : localStorage.getItem("name")}
              </div>
              <div className="d-flex align-items-center justify-content-center px-3 pt-3" title={localStorage?.getItem("email")} >
                {/* {localStorage.getItem("email").length >= 22
                  ? localStorage.getItem("email").substring(0, 22) + "..."
                  : localStorage.getItem("email")} */}
                <div className="text-truncate w-100 text-muted text-center fw-bold" >
                  {localStorage?.getItem("email") + " "}
                </div>
                {isVerified === 0 ? (
                  <BootstrapTooltip
                    title="Email isn't verified."
                    placement="top"
                  >
                    <InfoIcon sx={{ color: "#FB8C00" }} />
                  </BootstrapTooltip>
                ) : (
                  <BootstrapTooltip title="Email is verified." placement="top">
                    <CheckCircleIcon sx={{ color: "#1976d2" }} />
                  </BootstrapTooltip>
                )}
              </div>
            </div>
            <div
              className="profile-2-details"
              onClick={togglePasswordDivHandler}
            >
              {button1 ? (
                <button
                  className="Basic-Details-button-1"
                  style={{ textAlign: "left" }}
                  onClick={handleClick1}
                >
                  Basic Details
                </button>
              ) : (
                <button
                  className="Basic-Details-button-2"
                  style={{ textAlign: "left" }}
                  // onClick={handleClick1}
                >
                  Basic Details
                </button>
              )}

              {button2 ? (
                <button
                  className="Basic-Details-button-3"
                  style={{ textAlign: "left" }}
                  onClick={handleClick2}
                >
                  Professional Details
                </button>
              ) : (
                <button
                  className="Basic-Details-button-4"
                  style={{ textAlign: "left" }}
                  // onClick={handleClick2}
                >
                  Professional Details
                </button>
              )}

              {button3 ? (
                <button
                  className="Basic-Details-button-5"
                  style={{ textAlign: "left" }}
                  onClick={handleClick3}
                >
                  Other Details
                </button>
              ) : (
                <button
                  className="Basic-Details-button-6"
                  style={{ textAlign: "left" }}
                  // onClick={handleClick3}
                >
                  Other Details
                </button>
              )}
            </div>
          </div>
          <div className="Profile-2-box">
            {result ? (
              <div>
                {!toggleChangePasswordPage ? (
                  <div>
                    {openBasic && (
                      <Userprofilecompletion
                        completeprofile={completeprofile}
                      />
                    )}
                    {openPro && (
                      <Userprofilecompletion
                        completeprofile={completeprofile}
                      />
                    )}
                    {openOther && (
                      <Userprofilecompletion
                        completeprofile={completeprofile}
                      />
                    )}

                    {openBasic && (
                      <Basic
                        data={result}
                        duplicate={duplicate}
                        setEffectDependency={setEffectDependency}
                        getData={getData}
                        setToggleChangePasswordPage={
                          setToggleChangePasswordPage
                        }
                        setGetName={setGetName}
                      />
                    )}
                  </div>
                ) : (
                  <div>
                    {
                      <Changepassword
                        setToggleChangePasswordPage={
                          setToggleChangePasswordPage
                        }
                      />
                    }
                  </div>
                )}

                {openPro && (
                  <Professional
                    getData={getData}
                    data={result}
                    duplicate={duplicate}
                    setReplace={setReplace}
                    replace={replace}
                    setEffectDependency={setEffectDependency}
                  />
                )}
                {openOther && (
                  <Other
                    data={result}
                    duplicate={duplicate}
                    getData={getData}
                    setEffectDependency={setEffectDependency}
                  />
                )}
              </div>
            ) : (
              ""
            )}
            {/* {openBasic && <Basic data={result} />}
                 {openPro && <Professional data={result} />}
                    {openOther && <Other data={result} />}  */}
          </div>
        </div>
      </div>

      <div className="profile-mobile-page">
        <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", justifyContent: "center" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"

          >
            <Tab style={{ fontWeight: '600', fontSize: '17px' }} label="BASIC" {...a11yProps(0)} onClick={handleClick1} />
            <Tab style={{ fontWeight: '600', fontSize: '17px' }} label="PROFESSIONAL" {...a11yProps(1)} onClick={handleClick2} />
            <Tab style={{ fontWeight: '600', fontSize: '17px' }} label="OTHER" {...a11yProps(2)} onClick={handleClick3} />
          </Tabs>
        </Box>
        {openBasic && (
          <div className="fx" style={{ marginLeft: '22px', marginTop: '24px' }}>
            <Userprofilecompletion
              completeprofile={completeprofile}
            />
          </div>

        )}
        <div className="fx" style={{ marginLeft: '22px', marginTop: '24px' }}>
          {openPro && (
            <Userprofilecompletion
              completeprofile={completeprofile}
            />

          )}
        </div>
        {openOther && (
          <div className="fx" style={{ marginLeft: '22px', marginTop: '24px' }}>
            <Userprofilecompletion
              completeprofile={completeprofile}
            />
          </div>
        )}


        <CustomTabPanel value={value} index={0} sx={{ padding: '0px' }}>
          <div className="fx" style={{ fontWeight: 600, fontSize: '20px', marginLeft: '23px' }}>Profile Photo</div>
          <div className="mobile-image" >
            <div className="new-circle">
              {src ? (
                <img
                  className="rounded-circle img-fluid uploaded-image"
                  style={{ width: "135px", height: "135px" }}
                  src={src}
                />
              ) : (
                <Avatar
                  className="avatar"
                  sx={{
                    bgcolor: green[500],
                    width: "135px",
                    height: "135px",
                    fontSize: "45px",
                    fontWeight: "500",
                  }}
                >
                  {localStorage.getItem("name")?.split("")[0]
                    ? localStorage.getItem("name")?.split("")[0]
                    : "N"}
                </Avatar>
              )}

            </div>
            <div className="mobile-image-upload">


              <label htmlFor="upload-input" className="avatar-label2">
                <div className="edit-photo">
                  <EditIcon
                    style={{ fontSize: 24, color: 'white' }}
                  />
                </div>
                <div >
                  <input
                    id="upload-input"
                    onChange={imageFileHandler}
                    className="avatar"
                    hidden
                    name="image"
                    accept="image/png,image/jpeg"
                    type="file"
                  />
                </div>
              </label>


            </div>


          </div>
          <div className="app">
            {errorMessage && (
              <p
                style={{
                  fontSize: "14px",
                  color: "red",
                  textAlign: "center",
                  marginTop: "15px",
                  fontWeight: 600,
                }}
              >
                {errorMessage}
              </p>
            )}
          </div>
          <div className="new-profile-name text-capitalize">
            {/* {getName ? getName : localStorage.getItem("name")} */}
            {getName
              ? getName.length > 20
                ? getName.substring(0, 20) + "..."
                : getName
              : localStorage.getItem("name")}
          </div>
          <div className="new-profile-email text-truncate w-100 ">
            {localStorage.getItem("email").length >= 22
              ? localStorage.getItem("email").substring(0, 22) + "..."
              : localStorage.getItem("email")}
            {isVerified === 0 ? (
              <BootstrapTooltip
                title="Email isn't verified."
                placement="top"
              >
                <InfoIcon sx={{ color: "#FB8C00" }} />
              </BootstrapTooltip>
            ) : (
              <BootstrapTooltip title="Email is verified." placement="top">
                <CheckCircleIcon sx={{ color: "#1976d2" }} />
              </BootstrapTooltip>
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {/* {result?.details?.email ? result?.details?.email : "NA"} */}
            {isVerified === 0 ? (
              <Button
                className="fw-bold"
                sx={{ height: "24px", fontSize: '16px' }}
                variant="text"
                onClick={
                  handleResend}
              >
                Verify Email
              </Button>
            ) : (
              <></>
            )}
          </div>
          <Basic
            data={result}
            setEffectDependency={setEffectDependency}
            getData={getData}
            setToggleChangePasswordPage={setToggleChangePasswordPage}
            setGetName={setGetName}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Professional
            getData={getData}
            data={result}
            setReplace={setReplace}
            replace={replace}
            setEffectDependency={setEffectDependency}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Other
            data={result}
            getData={getData}
            setEffectDependency={setEffectDependency}
          />
        </CustomTabPanel>
      </div>
      <FooterZ />
    </div>
  );
};

export default Profilesection;
