import React, { useState, useEffect, useRef } from "react";
import Recommendedjobscard from "./Recommendedjobscard";
import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import Recommendedempty from "./Recommendedempty";
import { set } from "react-ga";
import Jobapplyshimmer from "./Jobapplyshimmer";
import SavedShimmer from "./SavedShimmer";

// const baseURL = process.env.REACT_APP_BASEURL;

const SavedJobs = () => {
  const[countjobs,setcountjobs]=useState(0)
  const[checkjobid,setcheckjobid]=useState([])
  const [getData, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const scrollContainerRef = useRef(null);
  const [totalCount, setTotalCount] = useState(0);
  const[summit,setsummit]= useState(false)
 




  let currentPage = 1;
  let isLoading = false;
  let more = true;

  const fetchMoreData = async () => {
    if (!more || isLoading) {
      return;
    }
  
    // Set isLoading to true before making the request
    isLoading = true;
    setLoading(true);
    try {
      const response = await axios.get(
        `user/saved/all-job/page-no/${currentPage}`,
        {
          headers: { authorization: document.cookie },
        }
      );
      currentPage = currentPage + 1;
  
      const newData = response.data.message.data;
      setTotalCount(response.data.message.total);
  
      if (newData.length > 0) {
        // Append new data to the existing data
        setData((prevData) => [...prevData, ...newData]);
        // setData(newData);
      } else {
        setHasMore(false);
        more = false;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Reset isLoading to false after the request is done
      isLoading = false;
      setLoading(false);
    }
  };
  

  useEffect(() => {
    
    fetchMoreData();
  }, []);

  const handleUnSave = async (id) => {
    setsummit(true)
    const response = await axios.post(`user/unsave-job`, {
      token: document.cookie,
      job_id: id,
      isRecommended: false,
    });
    toast.success("Job unsaved successfully", {
      hideProgressBar: true,
      closeOnClick: true,
    });

    let tempData = [...getData];
    tempData = tempData.filter((e) => e.joborder_id !== id);
    setData([...tempData]);

    if(tempData.length === 0 ) {
      fetchMoreData();
    }
  };

  

  useEffect(() => {

    const handleScroll = () => {

      const scrollPosition = container.scrollTop;
      const maxScrollHeight = container.scrollHeight - container.clientHeight;
      const threshold = 100;
      if(scrollPosition >= maxScrollHeight - threshold){
      fetchMoreData()      
      };

    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);

      // Clean up the event listener when the component unmounts
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  function findObjectsById(ids, data) {
    const result = [];
    ids.forEach(id => {
      const found = data.find(item => item.joborder_id === id);
      if (found) {
        result.push({ job: found.joborder_id, original_date_modified: found.date_modified });
      }
    });
    return result;
  }

  const result = findObjectsById(checkjobid, getData);
  console.log("result", result);

  const handlebulkapply = async () => {
  
    try {
      // setcountjobs(0);
      const response = await axios.post(`user/bulk-applied-quick/job`, {
        token: document.cookie,
        job_ids: result,
        isRecommended: false,
        // isShortlisted: 1,
      });
      toast.success("Job applied successfully", {
        hideProgressBar: true,
        closeOnClick: true,
      });
    
  
  
      
      // If the response is successful, call fetchMoreData immediately
      if (response.status === 200) {
        setcheckjobid([]);
        setcountjobs(0);
        if (!more || isLoading) {
          return;
        }
      
        // Set isLoading to true before making the request
        isLoading = true;
        setLoading(true);
        try {
          const response = await axios.get(
            `user/saved/all-job/page-no/${currentPage}`,
            {
              headers: { authorization: document.cookie },
            }
          );
          currentPage = currentPage + 1;
      
          const newData = response.data.message.data;
          setTotalCount(response.data.message.total);
      
          if (newData.length > 0) {
            // Append new data to the existing data
            // setData((prevData) => [...prevData, ...newData]);
            setData(newData);
          } else {
            setHasMore(false);
            more = false;
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          // Reset isLoading to false after the request is done
          isLoading = false;
          setLoading(false);
        }
      }
    } catch (err) {
      toast.error(err.response.data.error, {
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
  };


  
  return (
    <>
    <div className="bg-white">
      {
        getData.length > 0 ? <>
            <div className="container d-flex justify-content-between align-items-center pb-2 fx">
              <div className="fw-bold " style={{ zIndex: "1" }}>You can select upto 7 jobs to apply</div>
              <div>
                {countjobs === 0 ? <Button disabled={countjobs === 0 ? true : false} size="small" className="fw-bold" variant="contained">Apply to jobs</Button>
                  : <Button disabled={countjobs === 0 ? true : false} onClick={handlebulkapply} size="small" className="fw-bold" variant="contained">Apply to {countjobs} {countjobs === 1 ? "job" : "jobs"}</Button>}
              </div>
            </div>
        </> : <></>
      }
    </div>
      <div ref={scrollContainerRef} className="jbscrollsave px-3 px-lg-0">
        <div className="container p-0">
          <div className=" row m-0 justify-content-between">
            {loading && (
              <div className="">
                <SavedShimmer />
              </div>
            )}
            {!loading && getData.length > 0 ? getData.map((data, index) => (
                  <div key={index} className="col-lg-12 ps-0 pt-1">
                    <Recommendedjobscard
                      setcheckjobid={setcheckjobid}
                      countjobs={countjobs}
                      checkjobid = {checkjobid}
                      setcountjobs={setcountjobs}
                      onUnsave={(id) => handleUnSave(id)}
                      isSaveJob={true}
                      data={data}
                      summit={summit}
                    />
                  </div>
                ))
              : !loading && <Recommendedempty issaved={true} />}
          </div>
          {loading && (
            <div className="d-flex justify-content-center mt-3">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SavedJobs;
